import { Component, HostListener, isDevMode, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { HttpService, LoadingService } from '@opswat/services';
import { LayoutService, LoadingBarService } from '@opswat/ui';
import { ExpiredSessionDialogComponent } from './shared/components/expired-session-dialog/expired-session-dialog.component';
import { CONSTANT, DIALOG_HEIGHT, DIALOG_WIDTH, NLV_URLS } from './shared/constants';
import { AuthService } from './shared/services';
import { AccessControlService } from './shared/services/access-control/access-control.service';
import { CmJwtService } from './shared/services/jwt/cm-jwtToken.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'ocm-console-app';
	sessionExtensionDialogRef!: MatDialogRef<ExpiredSessionDialogComponent>;
	SESSION_EXPIRED_DIALOG_ID = 'session-expired';
	notAllowedSources = ['no-ls-user', '403', 'token-not-found'];
	nlvURLs = NLV_URLS;
	refreshTokenTimestamps: any = [];
	cancelledNavigation: any;
	isRedirectToPublicPage = false;
	subscriptions: any = {
		sessionExpired: null,
		refreshToken: null,
		showSessionExtensionPopup: null,
		router: null,
		accountNotFound: null,
	};

	@HostListener('document:click')
	public documentClick(): void {
		this.updateLastActivity();
	}

	constructor(
		public loadingService: LoadingService,
		public loadingBarService: LoadingBarService,
		public cmJwtService: CmJwtService,
		public authService: AuthService,
		public layoutService: LayoutService,
		public matDialog: MatDialog,
		public http: HttpService,
		public router: Router,
		private readonly accessControlService: AccessControlService
	) {
		this.onShowSessionExtensionPopup = this.onShowSessionExtensionPopup.bind(this);
	}
	ngOnInit() {
		/**
		 * Event emitter in @opswat/services emits values with each HTTP request
		 * Event emitter in @opswat/ui subscribes to them and control the loading bar (start, stop)
		 * This line points @opswat/ui emitter to @opswat/services emitter
		 */
		this.loadingBarService.emitter = this.loadingService.emitter;

		this.subscriptions.router = this.router.events.subscribe((evt) => {
			/**
			 * Record the page user tried to navigate to but
			 * got cancelled due to lack of session. URL of
			 * cancelled page is used as '?ref' parameter
			 * when logging the user out.
			 */
			if (evt instanceof NavigationCancel) {
				this.cancelledNavigation = evt;
			}

			if (evt instanceof NavigationStart) {
				this.isRedirectToPublicPage = this.nlvURLs.filter((nlvUrl) => evt.url.includes(nlvUrl)).length > 0;
			}

			if (!(evt instanceof NavigationEnd)) {
				return;
			}

			this.updateLastActivity();
			/**
			 	if (!this.isPublicPage()) {
					this.updateLastActivity();
				}
			 */

			this.layoutService.scrollToTop.emit();
		});

		/**
		 * Listen to sessionExpired event emitter
		 */
		this.subscriptions.sessionExpired = this.cmJwtService.sessionExpired.subscribe(this.onSessionExpired.bind(this));

		/**
		 * Listen to refreshToken event emitter
		 */
		this.cmJwtService.refreshToken.subscribe(() => {
			this.onRefreshToken();
		});

		/**
		 * Listen to showSessionExtensionPopup event emitter
		 */

		this.subscriptions.showSessionExtensionPopup = this.cmJwtService.showSessionExtensionPopup.subscribe(() => {
			if (!location.href.includes('nlv/login')) {
				this.onShowSessionExtensionPopup();
			}
		});

		/**
		 * Here we are listening to local storage value changes
		 * Use case: When there are multiple tabs open and
		 * all of them have the session extension popup displayed
		 * Problem: Once the session is extended in one of the tabs
		 * the other tabs still show the session extension popup
		 * because they don't know about what happened in a
		 * neighboring tab
		 * Solution: Listen to local storage token value changes.
		 * As soon as the local storage token is udpated from one
		 * tab, the other tabs will learn about the change, hide
		 * its popup and trigger cmJwtService.init
		 */
		window.addEventListener(
			'storage',
			(evt: StorageEvent) => {
				if (evt.key === CONSTANT.AUTH.JWT_TOKEN) {
					console.log(`${new Date()}: LST value changed`);
					if (this.sessionExtensionDialogRef instanceof MatDialogRef) {
						this.sessionExtensionDialogRef.close();
					}
					const token = this.cmJwtService.getJwtToken();
					if (token && !this.isRedirectToPublicPage) {
						this.cmJwtService.init('token-changed');
					}
				}
			},
			false
		);
	}

	onShowSessionExtensionPopup() {
		/**
		 * If session expired dialog is already opened, don't do anything
		 */
		if (this.matDialog.openDialogs.some((dialog) => dialog.id === this.SESSION_EXPIRED_DIALOG_ID)) {
			return;
		}
		this.sessionExtensionDialogRef = this.matDialog.open(ExpiredSessionDialogComponent, {
			disableClose: true,
			height: DIALOG_HEIGHT.SMALL,
			width: DIALOG_WIDTH.SMALL,
			id: this.SESSION_EXPIRED_DIALOG_ID,
		});
	}

	onRefreshToken() {
		console.log(`${new Date()}:onRefreshToken`);
		if (localStorage.getItem(CONSTANT.AUTH.JWT_STATUS)) {
			console.log('App: Oops another refreshing request is taking place');
			return;
		}

		this.refreshTokenTimestamps.push(Date.now());
		localStorage.setItem(CONSTANT.AUTH.JWT_STATUS, 'rf');
		localStorage.setItem(CONSTANT.AUTH.JWT_TIME, this.refreshTokenTimestamps.toString());
		this.authService.refreshToken().subscribe(
			(res: any) => {
				localStorage.removeItem(CONSTANT.AUTH.JWT_STATUS);
				const recentCount = this.refreshTokenTimestamps.filter((timestamps: any) => timestamps > Date.now() - 10000);
				if (recentCount.length > 5) {
					console.log('refresh-token-loop');
					this.cmJwtService.sessionExpired.emit('refresh-token-loop');
					return;
				}

				if (typeof res.token !== 'string') {
					throw new Error('Token not found in the response');
				}

				this.cmJwtService.saveToken(res.token, 'app-component-on-refresh');
			},
			(error: any) => {
				// force recaculate jwt. For case 3: Refresh will be retrigger
				// after 5 times failed refresh token. sesion expired with refresh loop
				localStorage.removeItem(CONSTANT.AUTH.JWT_STATUS);
				this.cmJwtService.init('failed-to-refresh-token');
			}
		);
	}

	/**
	 * Redirect to login BY LOGIN OUT
	 */
	onSessionExpired(source: any) {
		console.log(`Session expiration detected at ${source}`);
		const ref = this.cancelledNavigation ? encodeURI(this.cancelledNavigation.url) : null;
		if (source === 'token-not-found' && environment.onCloud) {
			// When login success, idp should auto redirect to OCM
			// But now on dev env, ocm need to reach to idp login to acquire token at start up
			// Which may lead to 'require_logout' guard take us here
			// only happend on dev env, in production, token not found will take us to idp login
			console.log(`Redirecting to login page to acquire tokenref: ${ref}`);
			this.authService.acquireTokenFromIdp(ref);
		} else {
			console.log(`Redirecting to login page by login out with ref: ${ref}`);
			this.authService.expiredSession().subscribe(
				(res: any) => {
					this.authService.redirectToLoginPage('', ref);
				},
				(err: any) => {
					this.authService.redirectToLoginPage('', ref);
				}
			);
		}
	}

	private updateLastActivity() {
		localStorage.setItem('la', `${Date.now()}`);
	}
}
