import { Injectable } from '@angular/core';
import { ToastService } from '..';

// Service content utilities that helps operating between page
// properly just share data
@Injectable({
	providedIn: 'root',
})
export class CmUtilService {
	private pageTouched = false;

	constructor(
		private readonly toastService: ToastService
	) { }

	markPageTouched(): void {
		this.pageTouched = true;
	}

	getPageTouched(): boolean {
		return this.pageTouched;
	}

	clearPageTouched() {
		this.pageTouched = false;
	}

	copyToClipBoard(value: string) {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(value);
			this.toastService.add({ severity: 'positive', summary: `Copied to clipboard.` });
		} else {
			var textArea = document.createElement('textarea');
			textArea.value = value;

			textArea.style.top = '0';
			textArea.style.left = '0';
			textArea.style.position = 'fixed';

			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				document.execCommand('copy');
				this.toastService.add({ severity: 'positive', summary: `Copied to clipboard.` });
			} catch (err) {
				this.toastService.add({ severity: 'negative', summary: `Oops, unable to copy.` });
			}

			document.body.removeChild(textArea);
		}
	}
}
