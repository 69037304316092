import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OPSWAT_PRODUCTS, URL_CONSTANT } from '@app/shared/constants';
import { AccessControlService } from '@app/shared/services/access-control/access-control.service';
import { Observable } from 'rxjs';
//TODO: move this to kiosk only
@Injectable({
	providedIn: 'root',
})
export class DashboardGuard  {
	constructor(private readonly accessControlService: AccessControlService, private readonly router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.accessControlService.allProductKioskHaveLicense()) {
			const stateUrl = state.url;
			const currentUrl = new URL(stateUrl, 'http://localhost:4200');

			if (
				currentUrl.pathname &&
				(currentUrl.pathname ===
					`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.MK5.INSTANCE_LIST}${URL_CONSTANT.INVENTORY.DASHBOARD}` ||
					currentUrl.pathname ===
						`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.KIOSK.INSTANCE_LIST}${URL_CONSTANT.INVENTORY.DASHBOARD}`)
			) {
				const newUrl = currentUrl.pathname.replace('dashboard', 'instances');
				this.router.navigateByUrl(newUrl);
				return false;
			}
		} else if (
			//this.accessControlService.productHasLicense(PERMISSION_PRODUCT_KEY.KIOSK_LINUX_L1001) ||
			//this.accessControlService.productHasLicense(PERMISSION_PRODUCT_KEY.KIOSK_LINUX_K3001)

			this.accessControlService.productHasLicense(OPSWAT_PRODUCTS.MK5.KEY) ||
			this.accessControlService.productHasLicense(OPSWAT_PRODUCTS.KIOSK.KEY)
		) {
			return true;
		}

		this.router.navigateByUrl(URL_CONSTANT.NOT_FOUND);
		return false;
	}
}
