export const PASSWORD_METER_COLOR = {
	EMPTY: 'gray',
	WEAK: 'red',
	AVERAGE: 'orange',
	GOOD: 'green',
	STRONG: 'light-green',
};

export const PASSWORD_ERROR_MESSAGE = {
	REQUIRED: 'Password is required',
	MIN_LENGTH: 'Minimum length not reached',
	INVALID: 'Password is invalid',
};

export const PASSWORD_METER_INDICATOR = {
	STRONG: 4,
	GOOD: 3,
	AVERAGE: 2,
	WEAK: 1
};

export const REGEX_UPPER_CASE = /(?=.*[A-Z])/;
export const REGEX_LOWE_CASE = /(?=.*[a-z])/;
export const REGEX_NUMBER = /(?=.*[0-9])/;
export const REGEX_SPECIAL_CHARACTER = /[^A-Za-z0-9]/;
