import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PERMISSION } from '@app/shared/constants';
import { checkDuplicates, noWhitespaceValidator } from '@app/shared/utils';

@Injectable({
	providedIn: 'root',
})
export class RenameMultipleService {
	isShowEditStatusIcon = false;
	isEditMultipleName = false;
	editNameForm = new UntypedFormArray([]);
	originEditNameFormValue: any;
	changedNames: any = [];
	duplicatesUser: any = [];

	getEditNameIcon(item: any, editNameResponse: any) {
		if (editNameResponse.successIds?.includes(item.id)) {
			return 'circle-check';
		}
		if (editNameResponse.failedIds?.includes(item.id)) {
			return 'unwanted';
		}
		return '';
	}

	enableRename(dataSource: any, keyOfNameValue = 'name', addInstanceId = false) {
		this.editNameForm.clear();
		this.isEditMultipleName = true;
		this.initEditForm(dataSource, keyOfNameValue, addInstanceId);
		this.editNameForm.markAllAsTouched();
	}

	initEditForm(dataSource: any, keyOfNameValue = 'name', addInstanceId = false) {
		dataSource.rows.forEach((item: any) => {
			let group = new UntypedFormGroup({});
			if (addInstanceId) {
				group = new UntypedFormGroup({
					id: new UntypedFormControl(item.id),
					name: new UntypedFormControl({ value: item[keyOfNameValue], disabled: item.permission === PERMISSION.VIEW_ONLY }, [
						Validators.required,
						noWhitespaceValidator,
					]),
					instanceId: new UntypedFormControl(item.instanceId),
				});
			} else {
				group = new UntypedFormGroup({
					id: new UntypedFormControl(item.id),
					name: new UntypedFormControl({ value: item[keyOfNameValue], disabled: item.permission === PERMISSION.VIEW_ONLY }, [
						Validators.required,
						noWhitespaceValidator,
					]),
				});
			}

			this.editNameForm.push(group);
		});
	}

	resetIconClass(dataSource: any) {
		dataSource.rows.forEach((item: any) => {
			item.editNameIconClass = '';
		});
	}

	cancelRename(dataSource: any) {
		this.isEditMultipleName = false;
		this.duplicatesUser = [];
		this.editNameForm.clear();
		this.resetIconClass(dataSource);
	}

	getChangedNames(dataSource: any, keyOfNameValue = 'name') {
		this.changedNames = this.editNameForm.value.filter((item: any) => {
			return dataSource.rows.findIndex((row: any) => row.id === item.id && item.name && item.name !== row[keyOfNameValue]) !== -1;
		});
	}

	handleValueChanges() {
		const dupplicateUtils = checkDuplicates('name', this.editNameForm, this.duplicatesUser);
		this.editNameForm = dupplicateUtils.form;
		this.duplicatesUser = dupplicateUtils.duplicatesUser;
	}
}
