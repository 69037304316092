import { Injectable } from '@angular/core';
import { RequestService } from '@app/shared/services';

import { Observable } from 'rxjs';
import { IDeviceOverview, IInstanceParamSearch, IKioskOption, IProductTypes } from '../../metadefender-kiosk/overview/dashboard/model';
import {
	IFireWallChartsParam,
	IMediaFirewallDashBoardFileProcess,
	IMediaFirewallDashBoardMediaType,
	IMediaFirewallDashboardDateChart,
} from '../../models';
import { MEDIA_FIREWALL_OVERVIEW } from './media-firewall-dashboard.api';
import { OPSWAT_PRODUCTS } from '@app/shared/constants';

@Injectable({
	providedIn: 'root',
})
export class MediaFirewallDashboardService {
	constructor(private readonly requestService: RequestService) {}

	getFirewallMediaTypeChart(data?: IFireWallChartsParam): Observable<IMediaFirewallDashBoardMediaType[]> {
		return this.requestService.action(MEDIA_FIREWALL_OVERVIEW.GET_MEDIA_CHART, data);
	}

	getFirewallProcessedFileChart(data?: IFireWallChartsParam): Observable<IMediaFirewallDashBoardFileProcess> {
		return this.requestService.action(MEDIA_FIREWALL_OVERVIEW.GET_PROCESSED_FILES, data);
	}

	getFirewallDateChart(data?: IFireWallChartsParam): Observable<IMediaFirewallDashboardDateChart[]> {
		return this.requestService.action(MEDIA_FIREWALL_OVERVIEW.GET_DATE_CHART, data);
	}

	getGroupByProductType(data?: IProductTypes): Observable<IDeviceOverview[]> {
		return this.requestService.action(MEDIA_FIREWALL_OVERVIEW.GET_GROUP_WITH_PRODUCT_TYPE, data);
	}

	getInstanceByGroupID(data?: IInstanceParamSearch): Observable<IKioskOption[]> {
		return this.requestService.action(MEDIA_FIREWALL_OVERVIEW.GET_KIOSK_WITH_GROUP_ID, data);
	}
}
