import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PublisherService } from '@app/shared/services/publisher/publisher.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AccountInformationResolver {
	constructor(private readonly publisherService: PublisherService) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		return this.publisherService.follow('accountInfo').pipe(take(1));
	}
}
