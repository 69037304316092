import { Component, EventEmitter, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UnsavedChangesDialogData } from '@app/shared/models/unsaved-changes-dialog.modal';

@Component({
	selector: 'app-unsaved-changes-dialog',
	templateUrl: './unsaved-changes-dialog.component.html',
})
export class UnsavedChangesDialogComponent {
	discardChanges = new EventEmitter();

	constructor(
		public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: UnsavedChangesDialogData
	) {}

	onCancel() {
		this.dialogRef.close(false);
	}

	onLeave() {
		this.dialogRef.close(true);
		this.data?.isInternalChanges && this.discardChanges.emit();
	}
}
