const processingOverviewName = 'Processing Overview';
const resourceOverviewName = 'Resource Overview';
const deviceOverviewName = 'Product Overview';

// do not change or remove this array
export const defaultKioskModules = [
	{
		categoryKey: 'deviceOverview',
		categoryName: deviceOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Status',
		key: 'status',
	},
	{
		categoryKey: 'deviceOverview',
		categoryName: deviceOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'License Status',
		key: 'licenseStatus',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Data Summary',
		key: 'dataSummary',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Top Group Based Threats',
		key: 'topGroupBasedThreats',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Top File Types',
		key: 'topFileTypes',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Processing Result',
		key: 'processingResult',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Media Sessions',
		key: 'mediaSessions',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Average Files And Time Per Session',
		key: 'averageFilesAndTimePerSession',
	},
	{
		categoryKey: 'resourceOverview',
		categoryName: resourceOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Kiosk Health',
		key: 'kioskHealth',
	},
	{
		categoryKey: 'resourceOverview',
		categoryName: resourceOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Kiosk Last Seen',
		key: 'kioskLastSeen',
	},
	{
		categoryKey: 'resourceOverview',
		categoryName: resourceOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Kiosk Version',
		key: 'kioskVersion',
	},
	{
		categoryKey: 'resourceOverview',
		categoryName: resourceOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Free Space',
		key: 'freeSpace',
	},
];

// do not change or remove this array
export const defaultMediaFirewallModules = [
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Verdicts',
		key: 'verdicts',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Blocked',
		key: 'blocked',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Scan Results',
		key: 'scanResults',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Total sessions',
		key: 'totalSessions',
	},
	{
		categoryKey: 'processingOverview',
		categoryName: processingOverviewName,
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		isDisplay: true,
		name: 'Sessions Per Media Type',
		key: 'sessionsPerMediaType',
	},
];
