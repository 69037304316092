import { CONSTANT } from '@app/shared/constants';

export const INVENTORY_GROUP = {
	get_group_list: {
		url: `${CONSTANT.URL_PREFIX}groups/search`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	get_group_list_instance: {
		url: `${CONSTANT.URL_PREFIX}instance/search`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	delete_Groups: {
		url: `${CONSTANT.URL_PREFIX}groups`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: true,
		headers: {},
	},
	get_instance_with_group_id: {
		url: `${CONSTANT.URL_PREFIX}instance/names`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	get_group_with_product_type: {
		url: `${CONSTANT.URL_PREFIX}instance/groups`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},

	group_info: {
		url: `${CONSTANT.URL_PREFIX}groups/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	deleteInstances: {
		url: `${CONSTANT.URL_PREFIX}instance`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: true,
		headers: {},
	},
	renameGroup: {
		url: `${CONSTANT.URL_PREFIX}groups`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	updateNameInstance: {
		url: `${CONSTANT.URL_PREFIX}instance`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	createNewGroup: {
		url: `${CONSTANT.URL_PREFIX}groups`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},
	assignInstanceToGroup: {
		url: `${CONSTANT.URL_PREFIX}instance/assign`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},

	GET_DEFAULT_GROUP: {
		url: `${CONSTANT.URL_PREFIX}groups/default/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},

	applyGroupConfiguration: {
		url: `${CONSTANT.URL_PREFIX}group/config/apply`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},

	verifyInstanceInfo: {
		url: `${CONSTANT.URL_PREFIX}group/config/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
};
