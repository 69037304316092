import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_CONSTANT } from '@app/shared/constants';
import { isEmpty } from '@app/shared/utils';
import { ReplaySubject } from 'rxjs';
import { PERMISSION, PERMISSION_KEY, PERMISSION_PREDEFINED } from '../../constants/permission.constant';

@Injectable({
	providedIn: 'root',
})
export class RbacService {
	public permissionsStage = new ReplaySubject(1);
	currentPermissionsStage = this.permissionsStage.asObservable();
	listPermissions: any;

	constructor(private readonly router: Router) { }

	savePermission(permissions: any) {
		this.permissionsStage.next(permissions);
	}

	loadPermission(key: string, permissions: any) {
		if (isEmpty(permissions) || !permissions.hasOwnProperty(key)) {
			return {};
		}

		return permissions[key] || {};
	}

	loadPermissionLevel(key: string, permissions: any) {
		const permission = this.loadPermission(key, permissions);
		if (isEmpty(permission)) {
			return PERMISSION.NONE;
		}

		if (key === PERMISSION_KEY.inventory) {
			if (!permission.isCustom) {
				return permission?.level || PERMISSION.NONE;
			}
			if (permission.isCustom) {
				return PERMISSION.VIEW_ONLY;
			}
		}

		return permission?.level || PERMISSION.NONE;
	}

	loadPermissionForProduct(productType: string, permissions: any) {
		const key = PERMISSION_KEY.inventory;
		const permission = this.loadPermission(key, permissions);
		if (isEmpty(permission)) {
			return PERMISSION.NONE;
		}

		if (!permission.isCustom) {
			return permission?.level || PERMISSION.NONE;
		}

		const productPermission = this.loadPermission(productType, permission);
		if (productPermission.isCustom) {
			if (productPermission.level === PERMISSION.FULL) {
				return PERMISSION.FULL;
			}
			return this.loadGroupPermission(productPermission, productPermission?.level);
		}

		return productPermission?.level || permission?.level;
	}

	loadPermissionForAddNewProduct(permissionKey: string, permissions: any) {
		const key = PERMISSION_KEY.inventory;
		const permission = this.loadPermission(key, permissions);
		if (isEmpty(permission)) {
			return PERMISSION.NONE;
		}

		if (!permission.isCustom || !permission.hasOwnProperty(permissionKey)) {
			return permission?.level || PERMISSION.NONE;
		}

		const productPermission = this.loadPermission(permissionKey, permission);
		return productPermission.level;
	}

	loadGroupPermission(productPermission: any, productPermissionLevel: string) {
		const groupsIncludeInPermission = productPermission?.groups;
		if (groupsIncludeInPermission.every((permission: any) => permission.level === PERMISSION.NONE)) {
			return productPermissionLevel;
		}

		if (groupsIncludeInPermission.some((permission: any) => permission.level === PERMISSION.FULL)) {
			return PERMISSION.FULL;
		}

		return PERMISSION.VIEW_ONLY;
	}

	loadPermissionForGroup(productType: string, groupId: string, permissions: any) {
		const key = PERMISSION_KEY.inventory;
		const permission = this.loadPermission(key, permissions);
		if (isEmpty(permission)) {
			return PERMISSION.NONE;
		}
		if (!permission.isCustom) {
			return permission?.level || PERMISSION.NONE;
		}

		if (!permission.hasOwnProperty(productType)) {
			return permission?.level || PERMISSION.NONE;
		}

		const productPermission = this.loadPermission(productType, permission);
		if (!productPermission.isCustom) {
			return productPermission?.level || PERMISSION.NONE;
		}

		const groupsIncludeInPermission = productPermission?.groups;
		const groupPermission = groupsIncludeInPermission.find((group: any) => group.id === groupId);
		if (!groupPermission) {
			return productPermission?.level || PERMISSION.NONE;
		}
		return groupPermission?.level || PERMISSION.NONE;
	}

	loadPermisionForTab(key: string, tabKey: string, permissions: any) {
		const permission = this.loadPermission(key, permissions);
		if (isEmpty(permission)) {
			return PERMISSION.NONE;
		}

		if (!permission.isCustom) {
			return permission?.level || PERMISSION.NONE;
		}

		return permission[tabKey] || PERMISSION.NONE;
	}

	loadPermissionPreDefined(roleName: string) {
		const rolePreDefined = {
			Administrator: PERMISSION.FULL,
			'View Only': PERMISSION.VIEW_ONLY,
		};
		return PERMISSION_PREDEFINED[rolePreDefined[roleName]];
	}

	handlePermissionForPage(key: string, permissions: any) {
		const permission = this.loadPermissionLevel(key, permissions);
		if (permission === PERMISSION.NONE) {
			this.router.navigate([URL_CONSTANT.PERMISSION_DENIED]);
			return false;
		}
		return true;
	}

	handlePermissionForProduct(permissionKey: string, permissions: any, acceptPermission: any) {
		const permission = this.loadPermissionForProduct(permissionKey, permissions);
		if (!acceptPermission.includes(permission)) {
			return false;
		}
		return true;
	}

	handlePermissionForGroup(key: string, groupId: any, permissions: any): boolean {
		const permission = this.loadPermissionForGroup(key, groupId, permissions);
		if (permission === PERMISSION.NONE) {
			this.router.navigate([URL_CONSTANT.PERMISSION_DENIED]);
			return false;
		}
		return true;
	}

	handlePermissionForTab(key: string, tabKey: string, permissions: any) {
		const permission = this.loadPermisionForTab(key, tabKey, permissions);
		if (permission === PERMISSION.NONE) {
			this.router.navigate([URL_CONSTANT.PERMISSION_DENIED]);
			return false;
		}
		return true;
	}

	handlePermissionForAddingNewGroup(permissionKey: any, permissions: any) {
		if (!permissions['inventory'].isCustom) {
			if (permissions['inventory'].level === PERMISSION.FULL) {
				return true;
			}

			this.router.navigate([URL_CONSTANT.PERMISSION_DENIED]);
			return false;
		}

		if (permissions['inventory'][permissionKey]) {
			if (permissions['inventory'][permissionKey].level === PERMISSION.FULL) {
				return true;
			}
		} else {
			if (permissions['inventory'].level === PERMISSION.FULL) {
				return true;
			}
		}

		this.router.navigate([URL_CONSTANT.PERMISSION_DENIED]);
		return false;
	}
}
