import {
	INSTANCE_HEALTH_STATUS_COLOR,
	INSTANCE_STATUS_COLOR,
	SETTING_VERSION_COLOR,
} from '@app/views/content-view/modules/inventory/constants';
import { IInstanceVersionInfo, SettingsStatus, SettingsType, SettingVersionColor } from '@app/views/content-view/modules/inventory/models';
import { CONSTANT } from '../constants';
import { dateHelper } from './date-helper';
import { textHelper } from './text-helper';

export function instanceStatusColor(status: any) {
	return INSTANCE_STATUS_COLOR[status];
}

export function instanceHealthStatusColor(status: any) {
	return INSTANCE_HEALTH_STATUS_COLOR[status];
}

export function instanceLicenseSatus(expiration: any, licenseType: string) {
	if (licenseType?.toLocaleLowerCase() === 'perpetual') {
		return CONSTANT.COLOR_LICENSE.OVER_90;
	}

	if (expiration) {
		const expirationDate = new Date(expiration);
		const currentDate = new Date();
		const timeDifference = expirationDate.getTime() - currentDate.getTime();
		const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

		if (daysDifference > 90) {
			return CONSTANT.COLOR_LICENSE.OVER_90;
		} else if (daysDifference > 30 && daysDifference <= 90) {
			return CONSTANT.COLOR_LICENSE.FROM_31_TO_90;
		} else if (daysDifference > 0 && daysDifference <= 30) {
			return CONSTANT.COLOR_LICENSE.FROM_1_TO_30;
		}
		return CONSTANT.COLOR_LICENSE.EXPIRED;
	} else {
		return CONSTANT.COLOR_LICENSE.NO_LICENSE;
	}
}

export function isCustomInstances(settingsVersion: string, settingsType: SettingsType) {
	return settingsVersion !== '0.0.0' && settingsType === 'CUSTOM';
}

export function setttingsStatusDisplay(settingsStatus: SettingsStatus, outdated: boolean): SettingsStatus | '' {
	if (outdated) {
		return 'OUT OF DATE';
	}
	return settingsStatus !== 'APPLIED' ? settingsStatus : '';
}

export function formatInstanceToDisplay(row: any, isCustomLastSeen = true) {
	const outdated = isOutdated(row.groupDto?.settingsVersion, row.settingsVersion, row.settingsType);
	if (isCustomLastSeen) {
		row.lastSeen = row.lastSeen !== 0 ? dateHelper.fromNow(row.lastSeen) : '-';
	}
	row.lastReportedString = row.lastReported !== 0 ? dateHelper.fromNow(row.lastReported) : '-';
	row.statusColor = instanceStatusColor(row.status);
	row.healthStatusColor = instanceHealthStatusColor(row.healthStatus);
	row.licenseStatus = instanceLicenseSatus(row.license?.expiration, row.license?.licenseType);
	row.settingVersionColor = getSettingsVersionColor(row.settingsType, row.settingsStatus, row.settingsVersion, outdated);

	// order statement is important
	row.isCustomInstances = isCustomInstances(row.settingsVersion, row.settingsType);
	row.tooltipInfo = configurationToolTip(row.settingsStatus, row.settingsVersion, outdated, row.isCustomInstances);
	row.settingsStatusDisplay = textHelper.capitalizeFirstLetter(setttingsStatusDisplay(row.settingsStatus, outdated));
	row.outdated = outdated;

	const versionInfo = compareProductVersion(row.groupDto.productVersion, row.version);
	row.versionColor = versionInfo.color;
	row.versionIcon = versionInfo.icon;
}

export function getSettingsVersionColor(
	settingsType: SettingsType,
	settingsStatus: SettingsStatus,
	settingsVersion: string,
	outdated: boolean
): SettingVersionColor | '' {
	if (settingsVersion === '0.0.0') {
		return 'warning';
	}

	const colorByStatus = SETTING_VERSION_COLOR[settingsType];
	return outdated ? colorByStatus['OUT OF DATE'] : colorByStatus[settingsStatus];
}

export function configurationToolTip(settingsStatus: SettingsStatus, settingsVersion: string, outdated: boolean, isCustom: boolean): any {
	let isWarning = false;
	let message = '';
	if (!outdated) {
		if (settingsStatus === 'APPLIED' && settingsVersion === '0.0.0') {
			isWarning = true;
			message = 'This instance has not been applied the group’s configuration yet.';
		}

		if (isCustom) {
			message = 'This instance has a custom configuration';
		}
	}

	return {
		message,
		isWarning,
	};
}

function versionOutdated(comparedVersion: string, currentVersion: string) {
	if (comparedVersion && currentVersion) {
		const comparedArr = comparedVersion.split('.');
		const currentArr = currentVersion.split('.');

		for (let i = 0; i < currentArr.length; i++) {
			if (Number(comparedArr[i]) > Number(currentArr[i])) {
				return true;
			}
		}
	}
	return false;
}

export function isOutdated(groupSettingVersion: string, instanceSettingsVersion: string, settingsType: SettingsType): boolean {
	return settingsType === 'BY_GROUP' && versionOutdated(groupSettingVersion, instanceSettingsVersion);
}

export function compareProductVersion(productVersion: string, instanceVersion: string): IInstanceVersionInfo {
	const defaultInfo = {
		color: '',
		icon: '',
	};
	if (!productVersion || !instanceVersion) {
		return defaultInfo;
	}

	if (productVersion === instanceVersion) {
		return defaultInfo;
	}
	const arrProductVersion = productVersion.split('.');
	const arrInstanceVersion = instanceVersion.split('.');
	const maxLength = arrProductVersion.length > arrInstanceVersion.length ? arrProductVersion.length : arrInstanceVersion.length;

	for (let i = 0; i < maxLength; i++) {
		arrInstanceVersion[i] = arrInstanceVersion[i] ? arrInstanceVersion[i] : '0';
		arrProductVersion[i] = arrProductVersion[i] ? arrProductVersion[i] : '0';

		if (isNaN(parseInt(arrInstanceVersion[i])) || isNaN(parseInt(arrProductVersion[i]))) {
			return {
				color: CONSTANT.COLOR.HIGHLIGHT,
				icon: '',
			};
		}

		if (parseInt(arrInstanceVersion[i]) > parseInt(arrProductVersion[i])) {
			return {
				color: CONSTANT.COLOR.HIGHLIGHT,
				icon: '',
			};
		}

		if (parseInt(arrInstanceVersion[i]) < parseInt(arrProductVersion[i])) {
			return {
				color: CONSTANT.COLOR.WARN,
				icon: 'download',
			};
		}
	}

	return defaultInfo;
}

export function getListOfSpecificValues(listOfObjects: any, key: string) {
	const list: any[] = [];
	listOfObjects.forEach((object: any) => {
		list.push(object[key]);
	});

	return list;
}

export function getLicenseBarClass(licenseExpiration: number, licenseExpiredDaysRange: any) {
	const licenseExpiredDays = dateHelper.getNumberOfDaysByTimestamp(new Date().getTime(), licenseExpiration);
	if (licenseExpiredDays > licenseExpiredDaysRange.low) {
		return 'gray';
	}
	if (licenseExpiredDaysRange.medium < licenseExpiredDays && licenseExpiredDays <= licenseExpiredDaysRange.low) {
		return 'black';
	}

	if (licenseExpiredDaysRange.high <= licenseExpiredDays && licenseExpiredDays < licenseExpiredDaysRange.medium) {
		return 'warning';
	}

	if (licenseExpiredDaysRange.high > licenseExpiredDays) {
		return 'danger';
	}

	return '';
}

export function moveInstancesInTableToAnotherTable(sourceInstances: any, destinationInstances: any): void {
	return sourceInstances.filter((instance: any) => {
		if (instance.isSelected === true) {
			const tempInstance = Object.assign({}, instance);
			tempInstance.isSelected = false;
			destinationInstances.push(tempInstance);
		}
		return instance.isSelected === false;
	});
}
