import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { SHA256, enc } from 'crypto-js';

@Injectable({
	providedIn: 'root',
})
export class PinService {
	private pinEnable = false;
	public pin = '';
	public status = 200;
	public error = '';
	public invalidPin = false;
	constructor(public readonly router: Router) {}

	get pinValidation() {
		return this.pinEnable;
	}

	set pinValidation(enable: boolean) {
		this.pinEnable = enable;
	}

	get pinInvalid() {
		return this.invalidPin;
	}

	set pinInvalid(status: boolean) {
		this.invalidPin = status;
	}

	get pinError() {
		return this.error;
	}

	set pinError(error: string) {
		this.error = error;
	}

	get pinState() {
		return this.pin;
	}

	set pinState(pin: string) {
		this.pin = pin;
	}

	get pinStatus() {
		return this.status;
	}

	set pinStatus(status: number) {
		this.status = status;
	}

	resetPinStates() {
		this.invalidPin = false;
		this.error = '';
		this.pin = '';
		this.status = 200;
	}

	hashPin(pin: string) {
		return SHA256(pin).toString(enc.Hex);
	}

	redirectToUserProfile() {
		window.open(environment.ssoChangePasswordUrl, '_blank');
	}
}
