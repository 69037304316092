import { CONSTANT, VALIDATION_CONSTANT } from '../constants';

export const downloadFile = (data: any, disposition: string, type: string) => {
	const fileName = getDownloadFileName(disposition);
	const blob = new Blob([data], { type: CONSTANT.APPLICATION_TYPE[type.toUpperCase()] });
	const a = document.createElement('a');
	document.body.appendChild(a);
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	const el = document.querySelector(`a[download="${fileName}"]`);
	if (el) {
		document.body.removeChild(el);
		window.URL.revokeObjectURL(url);
	}
};

export const getDownloadFileName = (disposition: string) => {
	let filename = '';
	const filenameRegex = VALIDATION_CONSTANT.FILE_NAME_DOWNLOAD.REGEX;
	const matches = filenameRegex.exec(disposition);
	if (matches != null && matches[1]) {
		filename = matches[1].replace(/['"]/g, '');
	}
	return filename;
};
