//SQIgnore
import { AxisChartOptions, NonAxisChartOptions } from '../models';

export const stackedBarOptions: Partial<AxisChartOptions> = {
	series: [],
	colors: [],
	chart: {
		height: 35,
		width: '100%',
		type: 'bar',
		stacked: true,
		toolbar: {
			show: false,
		},
		offsetY: -10,
	},
	grid: {
		show: false,
		padding: {
			left: -15,
			top: -10,
			bottom: -10,
		},
		xaxis: {
			lines: {
				show: false,
			},
		},
		yaxis: {
			lines: {
				show: false,
			},
		},
	},
	legend: {
		show: false,
	},
	stroke: {
		width: 1,
		colors: ['#fff'],
	},

	dataLabels: {
		enabled: false,
		style: {
			colors: ['#000'],
		},
	},
	plotOptions: {
		bar: {
			horizontal: true,
			distributed: false,
			barHeight: '100%',
			columnWidth: '100%',
		},
	},
	xaxis: {
		labels: {
			show: false,
		},
		axisTicks: {
			show: false,
		},
		axisBorder: { show: false },
	},
	yaxis: {
		show: false,
		labels: {
			show: false,
			align: 'left',
			offsetX: -15,
			offsetY: 2,
			style: {
				fontSize: '13px',
			},
		},
	},
	states: {
		hover: {
			filter: {
				type: 'lighten',
			},
		},
	},
	tooltip: {
		enabled: true,
		shared: false,
		intersect: true,
		x: {
			show: false,
		},
		y: {},
	},
};

export const donutChartOptions: Partial<NonAxisChartOptions> = {
	series: [],
	colors: [],
	labels: [],
	chart: {
		width: 220,
		type: 'donut',
		offsetX: -15,
	},
	legend: {
		show: true, // Setting this to 'false' will hide the legend
		position: 'top',
		horizontalAlign: 'left',
		//offsetX: -30,
		fontFamily: 'Roboto, Arial, sans-serif',
		markers: {
			width: 8,
			height: 8,
		},
		onItemHover: {
			highlightDataSeries: false,
		},
		itemMargin: {
			horizontal: 10,
		},
	},
	plotOptions: {
		pie: {
			offsetX: -35,
			offsetY: 5,
			donut: {
				size: '60%',
				labels: {
					show: true,

					total: {
						show: true,
						showAlways: true,
						label: '',
					},
					value: {
						show: true,
						fontFamily: 'Roboto, Arial, sans-serif',
						offsetY: 0,
						fontSize: '15px',
						fontWeight: 500,
					},
					name: {
						show: false,
						fontFamily: 'Roboto, Arial, sans-serif',
					},
				},
			},
		},
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		width: 0,
		colors: ['#fff'],
	},

	grid: {},
	states: {
		hover: {
			filter: {
				type: 'darken',
				value: 0.8,
			},
		},
	},
	responsive: [
		{
			breakpoint: 480,
			options: {
				chart: {
					width: 80,
				},
				legend: {
					position: 'bottom',
				},
			},
		},
	],
};
