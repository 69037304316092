import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { ServicesModule } from '@opswat/services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlobErrorHttpInterceptor, JwtInterceptor } from './core/interceptors';
import { CONSTANT, NLV_URLS, URL_CONSTANT } from './shared/constants';
import { AuthService, CmUtilService } from './shared/services';
import { CmJwtService } from './shared/services/jwt/cm-jwtToken.service';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AccessControlService } from './shared/services/access-control/access-control.service';
import { AccountService } from './shared/services/account/account.service';

export function initAppModule(evt: any, cmJwtService: CmJwtService) {
	const matchUrls = NLV_URLS.filter((nlvUrl) => evt.url.includes(nlvUrl));
	if (matchUrls.length > 0) {
		console.log(`${new Date()} nlv landing`);
		if (evt.url.includes(URL_CONSTANT.TCS)) {
			console.log(`${new Date()} application token initialization skipped to tcs`);
		}
	} else {
		console.log(`${new Date()} application token initialization`);
		cmJwtService.init('app-module');
	}
}

export function initContextPath() {
	const paths: string[] = location.pathname.split('/').splice(1, 1);

	if (paths && paths[0] && paths[0] === 'product-management') {
		return '/product-management';
	}

	return '';
}
// eslint-disable-next-line
export function initSessionExpiration(router: Router, cmJwtService: CmJwtService, authService: AuthService, accessControlService: AccessControlService, accountService: AccountService) {
	return () => {
		const subcription = router.events.subscribe((evt) => {
			if (evt instanceof NavigationStart) {
				if (environment.onCloud) {
					initAppModule(evt, cmJwtService);
				} else {
					authService.isSetupWizard().subscribe(
						(isSetupWizard) => {
							if (isSetupWizard) {
								initAppModule(evt, cmJwtService);
							}
						},
						() => {
							router.navigate([URL_CONSTANT.ERROR_PAGE], {
								queryParams: {
									code: 'service-unavailable',
								},
							});
						}
					);
				}
				subcription.unsubscribe();
			}
		});
		cmJwtService.successTokenInit.subscribe(() => {
			accessControlService.init(); // call apis to get Inventory Info and permission
			accountService.init(); // Call apis to get account from DX and CM
		});
	};
}

export function appInitSettup() {
	return () => {
		if (environment.production && !localStorage.getItem(CONSTANT.DEBUG_FLAG)) {
			console.log = () => { };
		}
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		ApolloModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		ServicesModule.forRoot(environment, {}),
		MatDialogModule,
		// to do remove it because of supporting sessions per media type
		MatTooltipModule,
	],
	providers: [
		{
			provide: APP_BASE_HREF,
			useFactory: initContextPath,
		},
		{
			provide: APOLLO_OPTIONS,
			useFactory(httpLink: HttpLink) {
				return {
					cache: new InMemoryCache({
						addTypename: false,
					}),
					link: httpLink.create({
						uri: environment.myOpswatEndpoint,
					}),
				};
			},
			deps: [HttpLink],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BlobErrorHttpInterceptor,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initSessionExpiration,
			deps: [Router, CmJwtService, AuthService, AccessControlService, AccountService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitSettup,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
