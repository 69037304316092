import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/shared/services';
import { CmJwtService } from '@app/shared/services/jwt/cm-jwtToken.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard  {
	environment = environment;
	constructor(private readonly cmJwtService: CmJwtService, private readonly authService: AuthService, public router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
		if (this.cmJwtService.isTokenInLocalStorage() && this.cmJwtService.isExpireToken()) {
			if (localStorage.getItem('required_logout') === 'true') {
				this.authService.terminateSession();
				return false;
			}
			return true;
		}

		return false;
	}
}
