import { Injectable } from '@angular/core';
import { CONSTANT } from '@app/shared/constants';
import { IGeneralTableCriteria } from '@app/shared/models';
import { environment } from '@env/environment';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PublisherService } from '../publisher/publisher.service';
import { AccountApiService } from './account-api.service';
import { ToastService } from '../toast.service';
import { IAccount } from '@app/views/content-view/modules/settings/model';

@Injectable({
	providedIn: 'root'
})
export class AccountService {

	constructor(
		private readonly accountApiService: AccountApiService,
		private readonly publisherService: PublisherService,
		private readonly toastService: ToastService,
	) { }

	init() {
		if (environment.onCloud) {
			this.getAccountInfo();
		} else {
			this.getAccountList();
		}
	}

	getAccountInfo() {
		const searchCriteria: IGeneralTableCriteria = {
			limit: CONSTANT.MAXIMUM_ACCOUNT_RECORD,
			page: 0,
		};

		const accountList$ = this.accountApiService.getAccountList(searchCriteria).pipe(catchError((error) => {
			return of(error.error);
		}))

		const dxAccounts$ = this.accountApiService.getDxAccountList().pipe(
			catchError((error) => {
				return of({ errors: [{ message: error }] });
			})
		);
		const dxProfile$ = this.accountApiService.getDxProfile().pipe(
			catchError((error) => {
				return of({ errors: [{ message: error }] });
			})
		);

		forkJoin([accountList$, dxAccounts$, dxProfile$]).subscribe(([accountList, dxAccountList, dxProfile]: any) => {
			const result: any = {};
			result.accountList = accountList;
			result.dxAccountList = dxAccountList;
			result.dxProfile = dxProfile;

			this.publisherService.publish('dxProfile', result.dxProfile);
			const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			if (dxProfile?.data?.profile) {
				localStorage.setItem('timezone', dxProfile?.data?.profile.timezone || browserTimezone);
			} else {
				const localTimezone = localStorage.getItem('timezone') || browserTimezone;
				localStorage.setItem('timezone', localTimezone);
			}
			this.publisherService.publish('accountInfo', { ...result })
		});

	}

	getAccountList() {
		const searchCriteria: IGeneralTableCriteria = {
			limit: CONSTANT.MAXIMUM_ACCOUNT_RECORD,
			page: 0,
		};
		this.accountApiService.getAccountList(searchCriteria).subscribe(
			(accountList: IAccount) => {
				const result: any = {
					dxProfile: {},
					accountList: {},
					dxAccountList: {},
				};

				result.accountList = accountList;
				this.publisherService.publish('accountInfo', { ...result })
			},
			(res: any) => {
				this.toastService.add({
					severity: 'negative',
					summary: res?.error?.error,
				});
			}
		);
	}

}
