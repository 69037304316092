export const SCAN_REPORT = {
	TIME_FORMAT: 'YYYY.MM.DD_HH.mm.ss',
	TIMEZONE: 'en-US',
	TITLE: 'OPSWAT MetaDefender Kiosk Scan Report',
	GENERAL: [
		{
			name: 'Kiosk Name:',
			key: 'name',
		},
		{
			name: 'Start Time:',
			key: 'startTime',
		},
		{
			name: 'Stop Time:',
			key: 'stopTime',
		},
		{
			name: 'Timezone',
			key: 'timezone',
		},
		{
			name: 'Session ID:',
			key: 'sessionId',
		},
		{
			name: 'MFT Guest ID(s):',
			key: 'vaultGuests',
		},
	],
	ERROR: 'SESSION INCOMPLETE:',
	SESSION_RESULT_NAME: 'SESSION RESULTS:',
	SESSION_RESULT: [
		{
			name: 'Total Files Processed:',
			key: 'totalFilesProcessed',
		},
		{
			name: 'Total Blocked Files:',
			key: 'totalBlockedFiles',
		},
		{
			name: 'Total Allowed Files:',
			key: 'totalAllowedFiles',
		},
		{
			name: 'Total Zero-Day Protection:',
			key: 'totalZeroDayProtection',
		},
		{
			name: 'Total Vulnerable Files:',
			key: 'totalVulnerableFiles',
		},
		{
			name: 'Total Skipped Files:',
			key: 'totalSkippedFiles',
		},
		{
			name: 'Total Data Loss Prevention:',
			key: 'totalDataLossPrevention',
		},
	],
	TRANSFER_RESULT_NAME: 'TRANSFER RESULTS',
	TRANSFER_RESULT_KEY: 'transferResults',
	TRANSFER_RESULT_MODE: 'mode',
	TRANSFER_RESULT: [
		{
			name: 'Total Files Processed:',
			key: 'totalProcessedFiles',
		},
		{
			name: 'Total Unprocessed Files:',
			key: 'totalUnprocessedFiles',
		},
		{
			name: 'Total Transfer Failures:',
			key: 'totalTransferFailures',
		},
	],
	USER_ID_NAME: 'USER IDENTIFICATION:',
	USER_ID_KEY: 'userIdentifications',
	USER_ID: [
		{
			name: 'Name',
			key: 'Name',
		},
	],
	INFECTED_FILE_NAME: 'INFECTED FILES:',
	INFECTED_FILE_KEY: 'infectedFiles',
	ZERO_DAY_NAME: 'ZERO-DAY PROTECTION:',
	ZERO_DAY_KEY: 'zeroDayProtections',
	SKIPPED_FILE_NAME: 'SKIPPED FILES:',
	SKIPPED_FILE_KEY: 'skippedFiles',
	VUL_NAME: 'VULNERABILITIES:',
	VUL_KEY: 'vulnerabilities',
	VUL_FILES_KEY: 'files',
	DLP_NAME: 'PROACTIVE DATA LOSS PREVENTION:',
	DLP_KEY: 'proactiveDataLossPreventions',
	SUPPLY_RISK_NAME: 'SUPPLY CHAIN RISK:',
	SUPPLY_RISK_KEY: 'supplyChainRisks',
	VUL_INFO: {
		name: 'SEVERITY:',
		key: 'severity',
	},

	FILE_INFO: [
		{
			name: 'PATH:',
			key: 'path',
		},
		{
			name: 'SHA256:',
			key: 'sha256',
		},
		{
			name: 'COMPANY NAME:',
			key: 'companyName',
		},
		{
			name: 'COUNTRY OF ORIGIN: ',
			key: 'countryOfOrigin',
		},
		{
			name: 'SENSITIVE DATA FOUND: ',
			key: 'sensitiveDataFound',
		},
	],
	FILE_INFO_SENSITIVE_FOUND: 'sensitiveDataFound',
	SECTION_DIV: '_______________________________________________________________________',
	ITEM_DIV: '------------------------------------------------------------------------',
};
