import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../models/toast.model';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	private readonly messageSource = new Subject<Message | Message[]>();
	private readonly clearSource = new Subject<string>();

	messageObserver = this.messageSource.asObservable();
	clearObserver = this.clearSource.asObservable();

	add(message: Message) {
		if (message) {
			this.messageSource.next(message);
		}
	}

	addAll(messages: Message[]) {
		if (messages && messages.length) {
			this.messageSource.next(messages);
		}
	}

	clear(key = '') {
		this.clearSource.next(key);
	}
}
