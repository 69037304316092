export const rewriteredirectParam = (url: string, key: string, value: string) => {
	const tree = new URL(url);
	if (tree.searchParams.has(key)) {
		const redirectUrl = tree.searchParams.get(key) + value;
		tree.searchParams.set(key, redirectUrl);
		return tree.href;
	} else {
		return url;
	}
};
