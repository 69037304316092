import * as moment_ from 'moment';
const moment = moment_;
import { FILTER_ENUM } from '../constants';
import dayjs from 'dayjs';
import { dateHelper } from '@app/shared/utils';
const ABB_LENGTH = 5; // Length condition to start abbreviate

export const abbreviateNumber = function (value: number) {
	if (value === undefined) {
		return '0';
	}

	if (value.toString().length < ABB_LENGTH) {
		return value.toString();
	}

	const suffixes = ['', 'K', 'M', 'B', 'T', 'Qd', 'Qn', 'Sx', 'Sp', 'Oc', 'N', 'Dc'];

	let indexSuffixes = 0;
	if (value === 0) {
		indexSuffixes = value;
	} else {
		indexSuffixes = Math.floor(Math.log(value) / Math.log(1000));
	}

	const numberBeforeSuffixes = parseFloat((value / Math.pow(1000, indexSuffixes)).toFixed(1));
	let result = numberBeforeSuffixes.toString();
	if (numberBeforeSuffixes === 1000) {
		result = '1';
		indexSuffixes++;
	}
	result += `${suffixes[indexSuffixes]}`;

	return result;
};

export const isAbbreviateNumber = function (value: number) {
	if (value === undefined) {
		return '0';
	}

	if (value.toString().length < ABB_LENGTH) {
		return true;
	}

	let indexSuffixes = 0;
	if (value === 0) {
		indexSuffixes = value;
	} else {
		indexSuffixes = Math.floor(Math.log(value) / Math.log(1000));
	}

	return indexSuffixes === 0;
};

export const numberWithCommas = (number: number) => {
	return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const startTimeBaseOnFilter = (currentFilter: any, startTime: any, amount: number) => {
	let startTimeString = '';
	if (currentFilter === FILTER_ENUM.ONEDAY) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('h A, DD MMM, YYYY');
	} else if (currentFilter === FILTER_ENUM.ONEMONTH || currentFilter === FILTER_ENUM.SEVENDAYS) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('DD MMM, YYYY');
	} else if (currentFilter === FILTER_ENUM.CUSTOM && amount <= 90) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('DD MMM, YYYY');
	} else {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('MMM, YYYY');
	}
	return startTimeString;
};

export const dataBaseOnFilter = (currentFilter: any) => {
	const dataZero: number[] = [];
	const filter = {
		[FILTER_ENUM.ONEDAY]: 24,
		[FILTER_ENUM.SEVENDAYS]: 7,
		[FILTER_ENUM.ONEMONTH]: 30,
		[FILTER_ENUM.ONEYEAR]: 12,
		[FILTER_ENUM.CUSTOM]: 'CUSTOM',
	};

	for (let index = 0; index < filter[currentFilter]; index++) {
		dataZero.push(0);
	}

	return dataZero;
};

export function findNextMultipleOfFour(num: number) {
	if (num < 20) {
		const remainder = num % 4;
		if (remainder === 0) {
			return num + 4;
		} else {
			return num + (4 - remainder);
		}
	}
	let multipleOfFour;
	const numberLength = num.toString().length;
	if (numberLength <= 3) {
		multipleOfFour = 20;
	} else if (numberLength <= 4) {
		multipleOfFour = 100;
	} else {
		multipleOfFour = 1000;
	}
	num = Math.floor((num + multipleOfFour - 1) / multipleOfFour) * multipleOfFour;

	return num;
}
