import { CONSTANT } from '@app/shared/constants';
import { SettingsStatusColorMapper } from '../models';

export const INVENTORY = {
	ACTION: {
		JSON: 'json',
		PDF: 'pdf',
		TXT: 'txt',
		DELETE: 'delete',
	},
};

export const SETTING_VERSION_COLOR: SettingsStatusColorMapper = {
	CUSTOM: {
		'OUT OF DATE': 'danger',
		APPLIED: 'primary',
		PENDING: 'warning',
		'NOT AVAILABLE': 'warning',
	},
	BY_GROUP: {
		'OUT OF DATE': 'danger',
		APPLIED: '',
		PENDING: 'warning',
		'NOT AVAILABLE': 'warning',
	},
};

export const VPACK_EVENT_LISTENER = {
	CLICKED_EDIT: 'ClickedEdit',
	CLICKED_SAVE: 'ClickedSave',
	CLICKED_DISCARD_CHANGES: 'ClickedDiscardChanges',
	SET_SAVE_STATE: 'SetSaveState',
	SHOW_MESSAGE_BOX: 'ShowMessageBox',
};

export const INSTANCE_STATUS_COLOR = {
	Connected: '',
	Disconnected: CONSTANT.COLOR.WARN,
	Pending: CONSTANT.COLOR.HIGHLIGHT,
	'No license': CONSTANT.COLOR.WARN,
};

export const INSTANCE_HEALTH_STATUS_COLOR = {
	'Critical Issues': 'health-critical',
	'Minor Issues': 'health-minor',
	Operational: 'health-operational',
	Unknown: 'health-unknown',
	'Significant Issues': 'health-significant',
};

/**
 * Inventory Dashboard Config Map
 * For mapping BE attribute
 */

export const PRODUCT_HEALTH = {
	operational: {
		label: 'Operational',
		color: '#008A00',
	},
	minorIssues: {
		label: 'Minor Issues',
		color: '#FDBD0D',
	},
	criticalIssues: {
		label: 'Critical Issues',
		color: '#D00300',
	},
	unknown: {
		label: 'Unknown',
		color: '#BCBFC3',
	},
	significantIssues: {
		label: 'Significant Issues',
		color: '#ED6706',
	},
};

export const PRODUCT_CONNECTIVITY = {
	active: {
		label: 'Active',
		color: '#1d6bfc',
	},
	inactive: {
		label: 'Inactive',
		color: '#d2d4d6',
	},
};

export const PRODUCT_VERSIONS = {
	LATEST: {
		label: 'Latest',
		color: '#2e9f2e',
	},
	'UP TO DATE': {
		label: 'Up to date',
		color: '#1d6bfc',
	},
	'SLIGHT BEHIND': {
		label: 'Slightly behind',
		color: '#61b6fd',
	},
	BEHIND: {
		label: 'Behind',
		color: '#707ea4',
	},
	'MAJOR BEHIND': {
		label: 'Major behind',
		color: '#a4a8ae',
	},
	Others: {
		label: 'Others',
		color: '#3d4a68',
	},
};

export const PRODUCT_LICENSES = {
	expire90PlusDays: {
		label: 'Licensed',
		color: '#008A00',
	},
	expire90Days: {
		label: 'Expiring in 90 days',
		color: '#fdbd0d',
	},
	expire30Days: {
		label: 'Expiring in 30 days',
		color: '#ed6706',
	},
	expired: {
		label: 'Expired',
		color: '#d00300',
	},
	noLicense: {
		label: 'No license',
		color: '#BCBFC3',
	},
};

export const DEFAULT_FONT_STYLE = 'Roboto, Arial, sans-serif';

export const DEVICE_LICENSE_STATUS_COLORS = {
	expire30Days: '#ED6706',
	expire90Days: '#FDBD0D',
	expire90PlusDays: '#008A00',
	expired: '#D00300',
	noLicense: '#990200',
	noInstances: '#A4A8AE',
	failed: '#DE4B49',
	infected: '#B50300',
	others: '#FED975',
	sensitiveDataFound: '#ED6706',
	vulnerableVerdictbySBOM: '#F5A871',
	adobeDocument: '#F07D2A',
	javascript: '#F5A871',
	zipArchive: '#FAD4B8',
	0: '#B50300',
	1: '#DE4B49',
	2: '#ED6706',
	3: '#F5A871',
	4: '#FED975',
};

export const BLOCKED_RESULT_STATUS_LABELS = {
	failed: 'Failed',
	infected: 'Infected',
	others: 'Others',
	sensitiveDataFound: 'Sensitive Data Found',
	vulnerableVerdictbySBOM: 'Vulnerable Verdict by SBOM',
	noInstances: 'No Data',
};

export const DEVICE_LICENSE_STATUS_LABELS = {
	expire30Days: '1-30 Days',
	expire90Days: '31-90 Days',
	expire90PlusDays: '90+ Days',
	expired: 'Expired',
	noLicense: 'No License',
	noInstances: 'No Instances',
};

export const FILE_TYPE_STATUS_LABELS = {
	adobeDocument: 'Adobe Document',
	javascript: 'Javascript',
	zipArchive: 'Zip archive',
	noInstances: 'No Data',
};
