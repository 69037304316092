import { Inject, Injectable, PLATFORM_ID, isDevMode } from '@angular/core';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { CmJwtService } from '../jwt/cm-jwtToken.service';
import { CONSTANT } from '../../constants';

@Injectable({
	providedIn: 'root',
})
export class CmCookiesService extends CookieService {
	CLOUD_DOMAIN = '.opswat.com';
	domain: string;
	constructor(@Inject(PLATFORM_ID) platformId: any, private readonly cmJwtService: CmJwtService) {
		super(document, platformId);
		this.domain = environment.onCloud ? this.CLOUD_DOMAIN : document.domain;
	}

	setDecryptedTokenToCookie() {
		const decryptedToken = this.cmJwtService.getJwtToken();
		if (environment.isHttps) {
			this.set(CONSTANT.AUTH.JWT_TOKEN, decryptedToken, undefined, '/', this.domain, true, 'Strict');
		} else {
			if (isDevMode()) {
				this.domain = document.domain;
			}
			this.set(CONSTANT.AUTH.JWT_TOKEN, decryptedToken, undefined, '/', this.domain, undefined, 'Strict');
		}
	}

	deleteOcmJwt() {
		if (environment.isHttps) {
			this.delete(CONSTANT.AUTH.JWT_TOKEN, '/', this.domain, true, 'Strict');
		} else {
			this.delete(CONSTANT.AUTH.JWT_TOKEN, '/', this.domain, undefined, 'Strict');
		}
	}

	deleteIDPCookies() {
		CONSTANT.MY_OPSWAT_COOKIES.forEach((name) => {
			this.delete(name, '/', this.domain);
		});
	}
}
