import { Injectable } from '@angular/core';
import { IGeneralTableCriteria } from '@app/shared/models';
import { RequestService } from '@app/shared/services';
import { dateHelper, downloadFile } from '@app/shared/utils';
import dayjs from 'dayjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import QRCode from 'qrcode';
import { Observable } from 'rxjs';
import { SCAN_REPORT } from '../../constants/scan-report.constant';
import { IInventoryScanHistory } from '../../models';
import { INVENTORY_SCAN_HISTORY } from './scan-history.api';
import { CONSTANT, TOKEN_USE } from '@app/shared/constants';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
	providedIn: 'root',
})
export class ScanHistoryService {
	DATE_TIME_FORMAT = 'MMM DD, YYYY h:mm A';
	constructor(private readonly requestService: RequestService) {}

	public getScanHistory(params: IGeneralTableCriteria, deloymentID: string): Observable<IInventoryScanHistory> {
		const api = JSON.parse(JSON.stringify(INVENTORY_SCAN_HISTORY.GET_SCAN_HISTORY));
		api.url += deloymentID + '/search';
		return this.requestService.action(api, params);
	}

	public getReport(url: string) {
		const api = {
			url: url,
			loading: true,
			method: CONSTANT.HTTP_METHOD.GET,
			customContext: TOKEN_USE.NONE,
		};
		return this.requestService.action(api);
	}

	public deleteReport(reportIds: any, hashedPin: string) {
		INVENTORY_SCAN_HISTORY.DELETE_REPORT.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(INVENTORY_SCAN_HISTORY.DELETE_REPORT, reportIds);
	}

	public downloadReport(criteria: any) {
		return this.requestService.action(INVENTORY_SCAN_HISTORY.DOWNLOAD_REPORT, criteria);
	}

	public downloadReportJson(criteria: any) {
		return this.requestService.action(INVENTORY_SCAN_HISTORY.DOWNLOAD_REPORT_JSON, criteria);
	}

	public editReportName(id: string, name: string) {
		const api = JSON.parse(JSON.stringify(INVENTORY_SCAN_HISTORY.EDIT_REPORT_NAME));
		api.url = `${api.url}${id}`;
		return this.requestService.action(api, { name: name });
	}

	public renameReports(reportInfos: any, hashedPin: string) {
		const api = JSON.parse(JSON.stringify(INVENTORY_SCAN_HISTORY.RENAME_REPORTS));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, reportInfos);
	}

	buildVaultGuestIds(vaultGuests: any) {
		if (vaultGuests?.length) {
			return vaultGuests.map((vaultGuest: any) => {
				return { text: vaultGuest.vaultGuestId };
			});
		}
		return '';
	}

	public exportPdfFile(items: any[], productName: string): void {
		const docDefinition: any[] = [];

		items.forEach((item, rIndex) => {
			const qrCodes: any = [];
			if (item.vaultGuests?.length) {
				item.vaultGuests.forEach((vaultGuests: any) => {
					if (vaultGuests?.vaultGuestId) {
						QRCode.toDataURL(vaultGuests.vaultGuestId, { margin: 0, type: 'image/jpeg' }, (error, qrUrl) => {
							if (error) {
								throw error;
							}
							qrCodes.push(qrUrl);
						});
					}
				});
			}

			const sessionError = item.session_error
				? [
						{
							text: SCAN_REPORT.SECTION_DIV,
							margin: [0, 10, 0, 10],
						},
						{ columns: [{ text: SCAN_REPORT.ERROR }, { text: item.session_error }] },
						{
							text: SCAN_REPORT.SECTION_DIV,
							margin: [0, 10, 0, 0],
						},
				  ]
				: [];
			docDefinition[rIndex] = {
				content: [
					{
						text: productName,
						margin: [0, 0, 0, 10],
					},

					{ columns: [{ text: SCAN_REPORT.GENERAL[0].name }, { text: item[SCAN_REPORT.GENERAL[0].key] }] },
					{
						columns: [
							{ text: SCAN_REPORT.GENERAL[1].name },
							{ text: dateHelper.formatUTCDate(item[SCAN_REPORT.GENERAL[1].key], this.DATE_TIME_FORMAT) },
						],
					},
					{
						columns: [
							{ text: SCAN_REPORT.GENERAL[2].name },
							{ text: dateHelper.formatUTCDate(item[SCAN_REPORT.GENERAL[2].key], this.DATE_TIME_FORMAT) },
						],
					},
					{ columns: [{ text: SCAN_REPORT.GENERAL[3].name }, { text: 'UTC' }] },
					{ columns: [{ text: SCAN_REPORT.GENERAL[4].name }, { text: item[SCAN_REPORT.GENERAL[4].key] }] },
					{ columns: [{ text: SCAN_REPORT.GENERAL[5].name }, this.buildVaultGuestIds(item[SCAN_REPORT.GENERAL[5].key])] },
				],
				defaultStyle: {
					fontSize: 10,
				},
			};
			if (qrCodes.length) {
				qrCodes.forEach((qrCode: any) => {
					docDefinition[rIndex].content.push({
						image: qrCode,
						width: 50,
						margin: [0, 10, 0, 0],
					});
				});
			}
			docDefinition[rIndex].content.push([
				...sessionError,

				{ text: SCAN_REPORT.SESSION_RESULT_NAME, margin: [0, 10, 0, 10] },

				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[0].name }, { text: item[SCAN_REPORT.SESSION_RESULT[0].key] }],
				},
				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[1].name }, { text: item[SCAN_REPORT.SESSION_RESULT[1].key] }],
				},
				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[2].name }, { text: item[SCAN_REPORT.SESSION_RESULT[2].key] }],
				},
				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[3].name }, { text: item[SCAN_REPORT.SESSION_RESULT[3].key] }],
				},
				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[4].name }, { text: item[SCAN_REPORT.SESSION_RESULT[4].key] }],
				},
				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[5].name }, { text: item[SCAN_REPORT.SESSION_RESULT[5].key] }],
				},
				{
					columns: [{ text: SCAN_REPORT.SESSION_RESULT[6].name }, { text: item[SCAN_REPORT.SESSION_RESULT[6].key] }],
				},
				{
					text: item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT_MODE]
						? `${SCAN_REPORT.TRANSFER_RESULT_NAME} (${
								item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT_MODE]
						  }):`
						: `${SCAN_REPORT.TRANSFER_RESULT_NAME} :`,
					margin: [0, 10, 0, 10],
				},
				{
					columns: [
						{ text: SCAN_REPORT.TRANSFER_RESULT[0].name },
						{ text: item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT[0].key] },
					],
				},
				{
					columns: [
						{ text: SCAN_REPORT.TRANSFER_RESULT[1].name },
						{ text: item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT[1].key] },
					],
				},
				{
					columns: [
						{ text: SCAN_REPORT.TRANSFER_RESULT[2].name },
						{ text: item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT[2].key] },
					],
				},

				{ text: SCAN_REPORT.USER_ID_NAME, margin: [0, 10, 0, 10] },

				{
					columns: [{ text: SCAN_REPORT.USER_ID[0].name }, { text: item[SCAN_REPORT.USER_ID_KEY][SCAN_REPORT.USER_ID[0].key] }],
				},

				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
				...this.getGeneralFileInfo(SCAN_REPORT.INFECTED_FILE_NAME, item[SCAN_REPORT.INFECTED_FILE_KEY]),
				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
				...this.getGeneralFileInfo(SCAN_REPORT.ZERO_DAY_NAME, item[SCAN_REPORT.ZERO_DAY_KEY]),
				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
				...this.getGeneralFileInfo(SCAN_REPORT.SKIPPED_FILE_NAME, item[SCAN_REPORT.SKIPPED_FILE_KEY]),
				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
				...this.getVulnerabilitiesInfo(SCAN_REPORT.VUL_NAME, item[SCAN_REPORT.VUL_KEY]),
				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
				...this.getGeneralFileInfo(SCAN_REPORT.DLP_NAME, item[SCAN_REPORT.DLP_KEY]),
				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
				...this.getGeneralFileInfo(SCAN_REPORT.SUPPLY_RISK_NAME, item[SCAN_REPORT.SUPPLY_RISK_KEY]),
				{
					text: SCAN_REPORT.SECTION_DIV,
					margin: [0, 10, 0, 0],
				},
			]);
		});

		docDefinition.forEach((item, docIndex) => {
			const currentItem = items[docIndex];
			const date = dateHelper.formatUTCDate(dayjs().valueOf(), SCAN_REPORT.TIME_FORMAT);
			pdfMake.createPdf(docDefinition[docIndex]).download(`${currentItem.sessionId}_${date}.pdf`);
		});
	}

	public getVulnerabilitiesInfo(name: string, file: any) {
		return [
			{ text: name, margin: [0, 10, 0, 10] },
			{
				ol: Object.keys(file).map((key) => {
					return [
						{ columns: [{ text: key }] },
						{
							columns: [{ text: SCAN_REPORT.VUL_INFO.name, width: 60 }, { text: file[key][SCAN_REPORT.VUL_INFO.key] }],
						},
						...this.getGeneralFileInfo('', file[key][SCAN_REPORT.VUL_FILES_KEY]),
					].filter(Boolean);
				}),
			},
		];
	}

	public getGeneralFileInfo(title: string, file: any[]) {
		if (file.length === 0) {
			return [title && { text: title, margin: [0, 10, 0, 10] }];
		}
		const infoList = file.map((item) => {
			const path = item[SCAN_REPORT.FILE_INFO[0].key] && {
				columns: [{ text: SCAN_REPORT.FILE_INFO[0].name, width: 50 }, { text: item[SCAN_REPORT.FILE_INFO[0].key] }],
			};
			const sha256 = item[SCAN_REPORT.FILE_INFO[1].key] && {
				columns: [{ text: SCAN_REPORT.FILE_INFO[1].name, width: 50 }, { text: item[SCAN_REPORT.FILE_INFO[1].key] }],
			};

			const companyName = item[SCAN_REPORT.FILE_INFO[2].key] && {
				columns: [{ text: 'COMPANY NAME: ', width: 130 }, { text: item[SCAN_REPORT.FILE_INFO[2].key] }],
			};
			const countryOfOrigin = item[SCAN_REPORT.FILE_INFO[3].key] && {
				columns: [{ text: SCAN_REPORT.FILE_INFO[3].name, width: 130 }, { text: item[SCAN_REPORT.FILE_INFO[3].key] }],
			};

			const sensitiveData = item[SCAN_REPORT.FILE_INFO[4].key] && [
				{ text: SCAN_REPORT.FILE_INFO[4].name },
				Object.keys(item[SCAN_REPORT.FILE_INFO_SENSITIVE_FOUND]).map((key) => [
					{
						columns: [{ text: key + ': ', width: 130 }, { text: item[SCAN_REPORT.FILE_INFO_SENSITIVE_FOUND][key] }],
					},
				]),
			];

			return [
				path,
				sha256,
				companyName,
				countryOfOrigin,
				sensitiveData,
				{
					text: SCAN_REPORT.ITEM_DIV,
					margin: [0, 0, 0, 0],
				},
			].filter(Boolean);
		});
		return [
			title && { text: title, margin: [0, 10, 0, 10] },
			{
				ol: infoList,
			},
		].filter(Boolean);
	}

	exportTxtFile(items: any[], productName: string) {
		items.forEach((item, index) => {
			const data = this.buildTxtFile(item, productName);
			const date = dateHelper.formatUTCDate(dayjs().valueOf(), SCAN_REPORT.TIME_FORMAT);
			this.downloadFile(data, `filename=${item.sessionId}_${date}.txt`, 'txt');
		});
	}

	buildTxtFile(item: any, productName: string) {
		let data = '';
		const sessionError = item.session_error;
		data = this.addLineText(data, `${productName}`);
		data = this.addLineText(data, '');
		// general info
		for (const category of SCAN_REPORT.GENERAL) {
			let line = '';
			const categoryName = category.name;
			const categoryKey = category.key;
			line = `${this.addSpaceForCol(categoryName)}`;
			if (categoryKey === 'vaultGuests') {
				item[categoryKey]?.forEach((element: any, index: number) => {
					let currentLine = `${this.addSpaceForCol('')}${element.vaultGuestId}\n`;
					if (index === 0) {
						currentLine = `${element.vaultGuestId}\n`;
					}
					line += currentLine;
				});
				// remove last \n;
				line = line.replace(/\n$/, '');
			} else if ((categoryKey === 'startTime' || categoryKey === 'stopTime') && item[categoryKey]) {
				line = `${this.addSpaceForCol(categoryName)}${dateHelper.formatUTCDate(item[categoryKey], this.DATE_TIME_FORMAT)}`;
			} else if (categoryKey === 'timezone') {
				line = `${this.addSpaceForCol(categoryName)}UTC`;
			} else if (item[categoryKey]) {
				line = `${this.addSpaceForCol(categoryName)}${item[categoryKey]}`;
			}
			data = this.addLineText(data, line);
		}

		if (sessionError) {
			data = this.addLineText(data, SCAN_REPORT.SECTION_DIV);
			data = this.addLineText(data, '');
			data = this.addLineText(data, `${this.addSpaceForCol(SCAN_REPORT.ERROR)}${sessionError}`);
			data = this.addLineText(data, SCAN_REPORT.SECTION_DIV);
		}

		data = this.addLineText(data, '');
		data = this.addLineText(data, SCAN_REPORT.SESSION_RESULT_NAME);
		data = this.addLineText(data, '');

		SCAN_REPORT.SESSION_RESULT.forEach((category) => {
			const line = `${this.addSpaceForCol(category.name)}${item[category.key]}`;
			data = this.addLineText(data, line);
		});

		data = this.addLineText(data, '');
		if (item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT_MODE]) {
			data = this.addLineText(
				data,
				`${SCAN_REPORT.TRANSFER_RESULT_NAME} (${item[SCAN_REPORT.TRANSFER_RESULT_KEY][SCAN_REPORT.TRANSFER_RESULT_MODE]})`
			);
		} else {
			data = this.addLineText(data, `${SCAN_REPORT.TRANSFER_RESULT_NAME}`);
		}
		data = this.addLineText(data, '');

		SCAN_REPORT.TRANSFER_RESULT.forEach((category) => {
			const line = `${this.addSpaceForCol(category.name)}${item[SCAN_REPORT.TRANSFER_RESULT_KEY][category.key]}`;
			data = this.addLineText(data, line);
		});

		data = this.addLineText(data, '');
		data = this.addLineText(data, SCAN_REPORT.USER_ID_NAME);
		data = this.addLineText(data, '');

		SCAN_REPORT.USER_ID.forEach((category) => {
			let line = '';
			if (item[SCAN_REPORT.USER_ID_KEY][category.key]) {
				line = `${this.addSpaceForCol(category.name)}${item[SCAN_REPORT.USER_ID_KEY][category.key]}`;
			} else {
				line = `${this.addSpaceForCol(category.name)}`;
			}
			data = this.addLineText(data, line);
		});

		data = this.addFilesInfo(data, SCAN_REPORT.INFECTED_FILE_NAME, item[SCAN_REPORT.INFECTED_FILE_KEY]);
		data = this.addFilesInfo(data, SCAN_REPORT.ZERO_DAY_NAME, item[SCAN_REPORT.ZERO_DAY_KEY]);
		data = this.addFilesInfo(data, SCAN_REPORT.SKIPPED_FILE_NAME, item[SCAN_REPORT.SKIPPED_FILE_KEY]);
		data = this.addVulnerbilityInfo(data, SCAN_REPORT.VUL_NAME, item[SCAN_REPORT.VUL_KEY]);
		data = this.addFilesInfo(data, SCAN_REPORT.DLP_NAME, item[SCAN_REPORT.DLP_KEY]);
		data = this.addFilesInfo(data, SCAN_REPORT.SUPPLY_RISK_NAME, item[SCAN_REPORT.SUPPLY_RISK_KEY]);
		data = this.addLineText(data, SCAN_REPORT.SECTION_DIV);
		return data;
	}

	addVulnerbilityInfo(data: string, category: string, vulInfo: any) {
		data = this.addLineText(data, '');
		data = this.addLineText(data, SCAN_REPORT.SECTION_DIV);
		data = this.addLineText(data, '');
		data = this.addLineText(data, category);
		data = this.addLineText(data, '');

		Object.entries(vulInfo).forEach(([key, value], index: number) => {
			const vulIndex = index + 1;
			data = this.addLineText(data, `${this.addSpaceForCol(vulIndex.toString(), 4)}${key}`);
			const vul: any = value;
			data = this.addLineText(
				data,
				`${this.addSpaceForCol('', 4)}${this.addSpaceForCol(SCAN_REPORT.VUL_INFO.name, 4)}${vul[SCAN_REPORT.VUL_INFO.key]}`
			);

			const fileInfo = vul[SCAN_REPORT.VUL_FILES_KEY];
			fileInfo.forEach((file: any, i: number) => {
				const order = i + 1;
				SCAN_REPORT.FILE_INFO.forEach((cate, findex) => {
					if (file[cate.key]) {
						if (cate.key === SCAN_REPORT.FILE_INFO_SENSITIVE_FOUND) {
							data = this.addLineText(data, `${this.addSpaceForCol('', 20)}${cate.name}`);
							Object.entries(file[cate.key]).forEach(([fkey, fvalue]) => {
								const line = `${this.addSpaceForCol('', 20)}${this.addSpaceForCol(fkey, 40)}${fvalue}`;
								data = this.addLineText(data, line);
							});
						} else {
							const line =
								findex === 0
									? `${this.addSpaceForCol('', 4)}${this.addSpaceForCol(order.toString(), 10)}${this.addSpaceForCol(
											cate.name,
											20
									  )}${file[cate.key]}`
									: `${this.addSpaceForCol('', 14)}${this.addSpaceForCol(cate.name, 20)}${file[cate.key]}`;
							data = this.addLineText(data, line);
						}
					}
				});
			});
			data = this.addLineText(data, SCAN_REPORT.ITEM_DIV);
		});
		return data;
	}

	addFilesInfo(data: string, category: string, fileInfo: any) {
		data = this.addLineText(data, '');
		data = this.addLineText(data, SCAN_REPORT.SECTION_DIV);
		data = this.addLineText(data, '');
		data = this.addLineText(data, category);
		data = this.addLineText(data, '');
		fileInfo.forEach((file: any, i: number) => {
			const order = i + 1;
			SCAN_REPORT.FILE_INFO.forEach((cate, index) => {
				if (file[cate.key]) {
					if (cate.key === SCAN_REPORT.FILE_INFO_SENSITIVE_FOUND) {
						data = this.addLineText(data, `${this.addSpaceForCol('', 10)}${cate.name}`);
						Object.entries(file[cate.key]).forEach(([key, value]) => {
							const line = `${this.addSpaceForCol('', 20)}${this.addSpaceForCol(key, 32)}${value}`;
							data = this.addLineText(data, line);
						});
					} else {
						const line =
							index === 0
								? `${this.addSpaceForCol(order.toString(), 8)}${this.addSpaceForCol(cate.name, 20)}${file[cate.key]}`
								: `${this.addSpaceForCol('', 8)}${this.addSpaceForCol(cate.name, 20)}${file[cate.key]}`;
						data = this.addLineText(data, line);
					}
				}
			});
			data = this.addLineText(data, SCAN_REPORT.ITEM_DIV);
		});

		return data;
	}
	addLineText(data: string, line: string) {
		return data.concat(line).concat('\n');
	}
	addSpaceForCol(text = '', align = 40) {
		let line = `${text}`;
		for (let i = 0; i < align - text.length; i++) {
			line = `${line}\x20`;
		}
		return line;
	}

	downloadFile(data: any, filename: string, type: string) {
		downloadFile(data, filename, type);
	}

	downloadJSONReports(data: any, productName: string, instanceName: string) {
		data.forEach((element: any) => {
			element.productName = productName;
			element.instanceName = instanceName;
			element.timezone = 'UTC';

			const date = dateHelper.formatUTCDate(dayjs().valueOf(), SCAN_REPORT.TIME_FORMAT);
			downloadFile(JSON.stringify(element), `filename=${element.sessionID || element.name}_${date}.json`, 'application/json');
		});
	}
}
