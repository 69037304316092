import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { URL_CONSTANT } from '@app/shared/constants';
import { AccessControlService } from '@app/shared/services/access-control/access-control.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RbacService } from '../../shared/services/rbac/rbac.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionProductGuard  {
	constructor(
		private readonly router: Router,
		private readonly rbacService: RbacService,
		private readonly accessControlService: AccessControlService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return new Observable<boolean | UrlTree>((observer) => {
			const permissionKey = route?.data?.permissionKey || route?.parent?.data?.permissionKey;
			const acceptPermission = route?.data?.permission;

			return this.accessControlService.getInventoryInfo().subscribe(
				(event: any) => {
					if (!this.accessControlService.productHasLicense(permissionKey)) {
						return observer.next(this.router.parseUrl(URL_CONSTANT.NOT_FOUND));
					}
					return this.rbacService.currentPermissionsStage.pipe(take(1)).subscribe((permissions) => {
						const stateUrl = state.url;
						// can use http://localhost:4200 or whatever URL is needed to create the currentUrl, and it will not impact the flow.
						const currentUrl = new URL(stateUrl, 'http://localhost:4200');

						const hasPermission = this.rbacService.handlePermissionForProduct(permissionKey, permissions, acceptPermission);
						if (!hasPermission) {
							return observer.next(this.router.parseUrl(`${currentUrl.pathname}${URL_CONSTANT.PERMISSION_DENIED}`));
						}
						return observer.next(hasPermission);
					});
				},
				() => {
					return observer.next(this.router.parseUrl(URL_CONSTANT.NOT_FOUND));
				}
			);
		});
	}
}
