/* eslint-disable */
export const VALIDATION_CONSTANT = {
	// Each object can include some fields: MAXLENGTH, MINLENGTH, REGEX ...
	NAME: {
		MAXLENGTH: 256,
	},
	DESCRIPTION: {
		MAXLENGTH: 300,
	},
	ACTIVATION_KEY: {
		MAXLENGTH: 128,
	},
	DEPLOYMENT_ID: {
		MAXLENGTH: 50,
		MINLENGTH: 32,
	},
	PASSWORD: {
		MAXLENGTH: 128,
		MINLENGTH: 12,
	},
	EMAIL: {
		MAXLENGTH: 320,
		REGEX: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
	},
	USERNAME: {
		MAXLENGTH: 320,
	},
	SEARCH_TEXT: {
		MAXLENGTH: 256,
	},
	FILE_NAME_DOWNLOAD: {
		REGEX: /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
	},
	PIN: {
		MAXLENGTH: 256,
		MINLENGTH: 4,
		REGEX: /^([A-Za-z0-9])+$/,
	},
	PAGE_TITLE: {
		NUMBER_OF_SHOWN_CHARACTERS: 40,
		NUMBER_OF_SHOWN_CHARACTERS_FOR_GROUP_DETAIL_INSTANCE: 40,
	},
	SESSION_TIMEOUT: {
		MAX: 480,
		MIN: 60,
	},
	DATA_RETENTION: {
		MAX: 18250,
		MIN: 1,
	},
	LIMIT_NAME_LENGHT: 20,
	LIMIT_USER_TIMEOUT_LENGTH: 3,
	HOST: {
		MAXLENGTH: 256,
	},
	CONNECTION_SETTING_TIMEOUT: {
		MAXLENGTH: 32,
	},
	BASE_DN: {
		MAXLENGTH: 256,
	},
	PORT: {
		MAX: 65535,
		MIN: 0,
	},
	IDP_GROUP: {
		MAXLENGTH: 2048,
		MINLENGTH: 1,
	},
	URL: {
		MAXLENGTH: 2048,
		REGEX: /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
	},
	CHALLENGE_CODE: {
		MAXLENGTH: 10,
		MINLENGTH: 10,
	},
	LICENSE_KEY: {
		LENGTH: 39,
	},
	RECUR_MONTH: {
		MAX: 12,
		MIN: 1,
	},
	RECUR_DAY: {
		MAX: 31,
		MIN: 1,
	},
	RECUR_HOUR: {
		MAX: 23,
		MIN: 1,
	},
	RECUR_MINUTE: {
		MAX: 59,
		MIN: 1,
	},
	LICENSES: {
		FILE_LIMIT: 100,
		KEY_LENGTH: 39,
	},
	DOMAIN_WO_PARAM: {
		MAX_LENGTH: 255,
		REGEX: /^(http|https):\/\/(([a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*))(:\d{1,5})?\b([-a-zA-Z0-9()\/]*)$/,
	},
	DIRECTORY: {
		LOCAL: {
			LOGIN_POLICY_MAX_VALUE: 999,
			LOGIN_POLICY_MIN_VALUE: 0,
		},
	},
	SERVER_URL: {
		REGEX: /^(([a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*))(:\d{1,5})?$/,
		MAX_LENGTH: 255,
	},
	EXPIRATION_TIME: {
		MIN: 1,
		MAX: 99,
	}
};
/* eslint-enable */
