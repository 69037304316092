import { Injectable } from '@angular/core';
import { IGeneralTableCriteria } from '@app/shared/models';
import { RequestService } from '@app/shared/services';
import { formatInstanceToDisplay } from '@app/shared/utils';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
	IInventoryInstance,
	IInventoryInstanceInfo,
	IRenameInstancesResponse,
	IUpdateInstanceNameRequestParams,
	IUpdateNameRequestParams,
} from '../../models';
import { INVENTORY_INSTANCE } from './inventory.api';

@Injectable({
	providedIn: 'root',
})
export class InventoryInstanceService {
	constructor(private readonly requestService: RequestService) {}

	public getInstanceList(params: IGeneralTableCriteria): Observable<IInventoryInstance> {
		return this.requestService.action(INVENTORY_INSTANCE.GET_INSTANCE_LIST, params).pipe(
			map((response: any) => {
				response.rows.forEach((row: any) => {
					formatInstanceToDisplay(row);
				});
				return response;
			}),
			catchError((error: any) => {
				return throwError(error);
			})
		);
	}

	getOverviewInstance(params: IGeneralTableCriteria): Observable<IInventoryInstance> {
		return this.requestService.action(INVENTORY_INSTANCE.GET_INSTANCE_LIST, params);
	}

	getGroups(params: any): any {
		return this.requestService.action(INVENTORY_INSTANCE.GET_ALL_GROUPS, params);
	}

	public deleteInstances(instanceIds: string[], hashedPin: string) {
		INVENTORY_INSTANCE.DELETE_INSTANCES.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(INVENTORY_INSTANCE.DELETE_INSTANCES, instanceIds);
	}

	public getIntanceDetail(id: string): Observable<IInventoryInstanceInfo> {
		const api = JSON.parse(JSON.stringify(INVENTORY_INSTANCE.GET_INSTANCE_DETAIL));
		api.url = `${api.url}${id}`;
		return this.requestService.action(api);
	}

	public updateNameInstance(params: IUpdateNameRequestParams) {
		return this.requestService.action(INVENTORY_INSTANCE.UPDATE_INSTANCE_NAME, params);
	}

	public renameInstances(instanceInfos: IUpdateInstanceNameRequestParams[], hashedPin = ''): Observable<IRenameInstancesResponse> {
		const api = JSON.parse(JSON.stringify(INVENTORY_INSTANCE.RENAME_INSTANCE));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, instanceInfos);
	}

	public pickInstanceAsGroupConfiguration(params: any, hashedPin: string) {
		const api = JSON.parse(JSON.stringify(INVENTORY_INSTANCE.PICK_INSTANCE_AS_CONFIGURATION));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		api.url = `${api.url}${params.groupId}`;
		api.params = { instanceId: params.instanceId };
		return this.requestService.action(api);
	}

	public exportFilter(payload: IGeneralTableCriteria) {
		return this.requestService.action(INVENTORY_INSTANCE.EXPORT_FILTER, payload);
	}

	deactivateInstanceLicense(data: any) {
		return this.requestService.action(INVENTORY_INSTANCE.DEACTIVATE_LICENSE, data);
	}
}
