import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANT } from '@app/shared/constants';
import { LoadingService } from '@opswat/services';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TOKEN_SCHEME } from '../constants';
import { RequestOption } from '../models';

@Injectable({
	providedIn: 'root',
})
export class RequestService {
	private options: any;

	constructor(public http: HttpClient, public loadingService: LoadingService) {}

	action(api: any, data: any | null = null): Observable<any> {
		const method = api.method as string;

		if (api.use_mock && api.mock) {
			return of(api.mock);
		}

		// create a clone of option for possible later querry
		const option = this.createRequestOptions(api);

		return this.fetch(method, api, data, option);
	}

	public fetch(method: string, api: any, data: any, option: RequestOption): Observable<any> {
		switch (method) {
			case CONSTANT.HTTP_METHOD.GET:
				return this.getData(api, option);
			case CONSTANT.HTTP_METHOD.POST:
				return this.postData(api, data, option);
			case CONSTANT.HTTP_METHOD.PATCH:
				return this.patchData(api, data, option);
			case CONSTANT.HTTP_METHOD.DELETE:
				return this.deleteData(api, data, option);
			case CONSTANT.HTTP_METHOD.PUT:
				return this.putData(api, data, option);
			case CONSTANT.HTTP_METHOD.HEAD:
				return this.headData(api, option);
			default:
				return of(false);
		}
	}

	public headData(api: any, options: any) {
		if (api.loading) {
			this.loadingService.start(`get${api.url}`);
		}
		return this.http.head<any>(api.url, options).pipe(finalize(() => this.loadingService.complete(`get${api.url}`)));
	}

	public getData(api: any, options: any) {
		if (api.loading) {
			this.loadingService.start(`get${api.url}`);
		}
		return this.http.get<any>(api.url, options).pipe(finalize(() => this.loadingService.complete(`get${api.url}`)));
	}

	public postData(api: any, data: any, options: any) {
		if (api.loading) {
			this.loadingService.start(`post${api.url}`);
		}
		return this.http.post<any>(api.url, data, options).pipe(finalize(() => this.loadingService.complete(`post${api.url}`)));
	}

	public patchData(api: any, data: any, options: any) {
		if (api.loading) {
			this.loadingService.start(`post${api.url}`);
		}
		return this.http.patch<any>(api.url, data, options).pipe(finalize(() => this.loadingService.complete(`post${api.url}`)));
	}

	public deleteData(api: any, data: any, options: RequestOption) {
		let optionWithbody = options;
		if (data && Object.keys(data).length > 0) {
			optionWithbody = Object.assign({ body: data }, options);
		}
		if (api.loading) {
			this.loadingService.start(`delete${api.url}`);
		}
		return this.http.request('delete', api.url, optionWithbody).pipe(
			tap(
				(rest: HttpResponse<any>) => {
					this.loadingService.complete(`delete${api.url}`);
				},

				(err: any) => {
					this.loadingService.complete(`delete${api.url}`);
				}
			)
		);
	}

	public putData(api: any, data: any, options: any) {
		if (api.loading) {
			this.loadingService.start(`put${api.url}`);
		}
		return this.http.put<any>(api.url, data, options).pipe(finalize(() => this.loadingService.complete(`put${api.url}`)));
	}

	private createRequestOptions(api: any) {
		this.options = new RequestOption();

		if (api.hasOwnProperty('customContext')) {
			this.options.context = this.options.context.set(TOKEN_SCHEME, api.customContext);
		}

		if (!api.hasOwnProperty('contentType')) {
			this.options.headers = this.options.headers.set(CONSTANT.CONTENT_TYPE, CONSTANT.APPLICATION_TYPE.JSON);
		}

		if (api.hasOwnProperty('headers')) {
			const headerKeys = Object.keys(api.headers);
			headerKeys.forEach((val) => {
				this.options.headers = this.options.headers.set(val, api.headers[val]);
			});
		}

		if (api.hasOwnProperty('settings') && api.settings !== null) {
			this.options.headers = { settings: JSON.stringify(api.settings) };
		}

		if (api.hasOwnProperty('observe')) {
			this.options.observe = api.observe;
		}

		if (api.hasOwnProperty('params')) {
			this.options.params = api.params;
		}

		if (api.hasOwnProperty('reportProgress')) {
			this.options.reportProgress = api.reportProgress;
		}

		if (api.hasOwnProperty('withCredentials')) {
			this.options.withCredentials = api.withCredentials;
		}

		if (api.hasOwnProperty('responseType')) {
			this.options.responseType = api.responseType;
		}
		return this.options;
	}
}
