import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OPSWAT_PRODUCTS } from '@app/shared/constants';
import { RbacService } from '@app/shared/services';
import {
	PRODUCT_TYPE,
	PRODUCT_TYPE_KIOSK,
	PRODUCT_TYPE_MK5,
} from '@app/views/content-view/modules/inventory/metadefender-kiosk/overview/dashboard/constants/dashboard.constant';

import { Observable, ReplaySubject } from 'rxjs';
import { PublisherService } from '../publisher/publisher.service';
import { AccessControlApiService } from './access-control-api.service';

@Injectable({
	providedIn: 'root',
})
export class AccessControlService {
	inventoryInfo: any;
	inventoryUpdateSubject = new ReplaySubject(1);
	inventorySubject = new ReplaySubject(1);

	constructor(
		private readonly accessControlApiService: AccessControlApiService,
		private readonly rbacService: RbacService,
		private readonly publisherService: PublisherService,
		public router: Router,
	) { }

	init() {
		this.getInventoryData();
		this.getUserInfo();
	}

	getInventoryData() {
		this.accessControlApiService.getInventoryInfo().subscribe(
			(response) => {
				this.inventoryInfo = response ? response : null;
			},
			(error) => {
				// TODO: error case handling
				this.inventoryInfo = null;
			},
			() => {
				this.checkInventory();
				this.inventoryUpdateSubject.next(this.inventoryInfo);
				this.inventorySubject.next(this.inventoryInfo);
				this.inventorySubject.complete();
			}
		);
	}

	// to get Permission
	getUserInfo() {
		console.log(`${new Date()} application retriving account info`);
		this.accessControlApiService.userInfo().subscribe(
			(res: any) => {
				this.publisherService.publish('currentUser', res);
				let permissions = res?.permission;

				if (!permissions) {
					permissions = this.rbacService.loadPermissionPreDefined(res?.roleName);
				}
				this.rbacService.savePermission(permissions);

			},
			({ error, status }) => {
				if (error) {
					console.log(`${new Date()} application retriving account info error: ${error.error}`);
				}
			}
		);
	}

	getInventoryInfo(): Observable<any> {
		return this.inventoryUpdateSubject;
	}

	getInventoryInfoForMyOpswatLicense(): Observable<any> {
		return this.inventorySubject.asObservable();
	}

	isInfoFetched() {
		return this.inventoryInfo !== undefined;
	}

	isShowNoKioskProduct() {
		return (
			this.inventoryInfo?.kiosk.instance === 0 &&
			(this.inventoryInfo?.mk5.instance === 0 || (this.inventoryInfo?.mk5.instance > 0 && !this.inventoryInfo?.mk5.activated))
		);
	}

	isEmptyInventory() {
		return !Object.keys(OPSWAT_PRODUCTS).some((product) => {
			if (!this.inventoryInfo?.hasOwnProperty(OPSWAT_PRODUCTS[product].KEY)) {
				return false;
			}
			return this.inventoryInfo[OPSWAT_PRODUCTS[product].KEY]?.enable;
		});
	}

	allProductKioskHaveLicense() {
		return this.inventoryInfo?.mk5?.enable && this.inventoryInfo?.kiosk?.enable;
	}

	getAvailableKioskTypes() {
		if (!this.inventoryInfo?.mk5.enable && !this.inventoryInfo?.kiosk.enable) {
			return [];
		} else if (this.inventoryInfo?.mk5.enable && !this.inventoryInfo?.kiosk.enable) {
			return [PRODUCT_TYPE_MK5];
		} else if (!this.inventoryInfo?.mk5.enable && this.inventoryInfo?.kiosk.enable) {
			return [PRODUCT_TYPE_KIOSK];
		} else {
			return PRODUCT_TYPE;
		}
	}

	productHasLicense(key: string) {
		if (this.inventoryInfo?.hasOwnProperty(key)) {
			return this.inventoryInfo[key]?.enable;
		}
		return false;
	}

	checkInventory() {
		Object.keys(OPSWAT_PRODUCTS).forEach((product) => {
			if (this.inventoryInfo?.hasOwnProperty(OPSWAT_PRODUCTS[product].KEY)) {
				this.inventoryInfo[OPSWAT_PRODUCTS[product].KEY].enable = false;
				if (this.inventoryInfo[OPSWAT_PRODUCTS[product].KEY] && this.inventoryInfo[OPSWAT_PRODUCTS[product].KEY].group !== 0) {
					this.inventoryInfo[OPSWAT_PRODUCTS[product].KEY].enable = true;
				}
			}
		});
	}
}
