import { ApexGrid } from 'ng-apexcharts';
import { abbreviateNumber } from './helper';

export const chartSeries = {
	Threats: 'Threats',
	Media: 'Media',
	Files: 'Files',
	Sanitized: 'Sanitized',
	MediaWithThreats: 'Media w/ Threats',
	AverageTime: 'Average Time per Session (min)',
	AverageFiles: 'Average Files per Session',
};

export const gridChart: ApexGrid = {
	show: true,
	borderColor: '#EEEFF0',
	strokeDashArray: 0,
	position: 'back',
	xaxis: {
		lines: {
			show: true,
		},
	},
	yaxis: {
		lines: {
			show: true,
		},
	},
};

export const minWidthLabel = (number: number) => {
	if (number === 0) {
		return 10;
	}
	let length = abbreviateNumber(number / 4).length;
	for (let i = number / 2; i <= number; i += number / 4) {
		if (length < abbreviateNumber(i).length) {
			length = abbreviateNumber(i).length;
		}
	}
	const width = {
		1: 10,
		2: 15,
		3: 20,
		4: 25,
		5: 30,
		6: 35,
		7: 40,
		8: 45,
		9: 50,
	};

	return width[length];
};

export const averageNumber = (data: any) => {
	let total = 0;
	let count = 0;
	data?.forEach((num: number) => {
		if (num > 0) {
			total += num;
			count++;
		}
	});
	if (count === 0) {
		return 0;
	}

	return total / count;
};
