<div class="dialog-small__header">
	<h4>{{ data?.text?.title || 'Unsaved Changes' }}</h4>
	<mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-small__content">
	<div class="m-b-lg m-t-md">
		{{ data?.text?.description || 'There are unsaved changes on this page. Are you sure you want to leave?' }}
	</div>
</div>

<div class="dialog-small__footer">
	<mat-divider></mat-divider>
	<div class="flex flex-space-between m-t-md">
		<button id="cancelBtn" mat-button color="ghost" class="outline none-pl" type="button" (click)="onCancel()">Cancel</button>
		<div class="flex">
			<button id="leaveBtn" cdkFocusInitial mat-button color="primary" class="m-l-sm" (click)="onLeave()">
				{{ data?.text?.btn || 'Leave' }}
			</button>
		</div>
	</div>
</div>
