import { CONSTANT } from '@app/shared/constants';
import { gql } from 'apollo-angular';

export const ACCOUNT = {
	getInventoryInfo: {
		url: `${CONSTANT.URL_PREFIX}inventory/info`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	user_info: {
		url: `${CONSTANT.URL_PREFIX}users/details`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	getAccountList: {
		url: `${CONSTANT.URL_PREFIX}users/accounts`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
};

export const ORG_LIST = gql`
	query {
		organizations {
			id
			name
			ssoId
		}
	}
`;

export const DX_PROFILE = gql`
	query {
		profile {
			id
			firstName
			lastName
			title
			email
			userTypes
			country
			timezone
			usingOrganizationId
			currentOrganizationId
		}
	}
`;
