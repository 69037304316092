import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { CONSTANT } from '@app/shared/constants';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RbacService } from '../../shared/services/rbac/rbac.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionGuard  {
	constructor(private readonly rbacService: RbacService) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {

		return new Observable<boolean | UrlTree>((observer) => {
			const key = route?.data?.key || CONSTANT.EMPTY_STRING;
			this.rbacService.currentPermissionsStage.pipe(take(1)).subscribe((permissions) => {
				observer.next(this.rbacService.handlePermissionForPage(key, permissions));
			});
		});
	}
}
