import { Injectable } from '@angular/core';
import { RequestService } from '@app/shared/services';
import { IGeneralTableCriteria } from '@app/shared/models';
import { Observable } from 'rxjs';
import { INVENTORY_GROUP } from '..';
import {
	ICreateGroupResponse,
	IInventoryGroupInfo,
	IRenameGroupsResponse,
	IUpdateNameRequestParams,
	IUpdateGroupNameRequestParams,
} from '../../models';

@Injectable({
	providedIn: 'root',
})
export class InventoryGroupService {
	constructor(private readonly requestService: RequestService) {}

	public getGroupList(params: IGeneralTableCriteria, isAssignPage = false): Observable<any> {
		if (isAssignPage) {
			const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.get_group_list));
			api.params = {
				isAssignPage,
			};
			return this.requestService.action(api, params);
		}

		return this.requestService.action(INVENTORY_GROUP.get_group_list, params);
	}

	public deleteGroups(ids: string[], productType: string, hashedPin = ''): Observable<any> {
		const params = {
			productType,
			ids,
		};
		INVENTORY_GROUP.delete_Groups.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(INVENTORY_GROUP.delete_Groups, params);
	}

	public getGroupInfo(groupId: string): Observable<IInventoryGroupInfo> {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.group_info));
		api.url = `${api.url}${groupId}`;
		return this.requestService.action(api);
	}

	public getGroupListInstance(params: IGeneralTableCriteria): Observable<any> {
		return this.requestService.action(INVENTORY_GROUP.get_group_list_instance, params);
	}

	public deleteInstances(instanceIds: any, hashedPin = ''): Observable<any> {
		INVENTORY_GROUP.deleteInstances.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(INVENTORY_GROUP.deleteInstances, instanceIds);
	}

	public renameGroup(groupInfo: IUpdateGroupNameRequestParams, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.renameGroup));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		api.url += `/${groupInfo.id}`;
		return this.requestService.action(api, groupInfo);
	}

	public renameGroups(groupInfos: IUpdateGroupNameRequestParams[], hashedPin = ''): Observable<IRenameGroupsResponse> {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.renameGroup));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, groupInfos);
	}

	public updateNameInstance(params: IUpdateNameRequestParams) {
		return this.requestService.action(INVENTORY_GROUP.updateNameInstance, params);
	}

	public createNewGroup(groupInfo: any, hashedPin = ''): Observable<ICreateGroupResponse> {
		INVENTORY_GROUP.createNewGroup.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(INVENTORY_GROUP.createNewGroup, groupInfo);
	}

	public assignInstanceToGroup(params: any, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.assignInstanceToGroup));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, params);
	}

	public getDefaultGroup(productType: string): Observable<any> {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.GET_DEFAULT_GROUP));
		api.url = `${api.url}${productType}`;
		return this.requestService.action(api);
	}

	public applyGroupConfiguration(params: any, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.applyGroupConfiguration));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, params);
	}

	public getVerifyInstanceInfo(params: any) {
		const api = JSON.parse(JSON.stringify(INVENTORY_GROUP.verifyInstanceInfo));
		api.url = `${api.url}${params.groupId}/verify`;
		api.params = { instanceId: params.instanceId };

		return this.requestService.action(api, params);
	}

	getGroupByProductType(data?: any): Observable<any[]> {
		return this.requestService.action(INVENTORY_GROUP.get_group_with_product_type, data);
	}

	getInstanceByGroupID(data?: any): Observable<any[]> {
		return this.requestService.action(INVENTORY_GROUP.get_instance_with_group_id, data);
	}
}
