import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function checkDuplicates(keyForm: string, form: UntypedFormArray, duplicatesUser: any) {
	for (const index of duplicatesUser) {
		form.at(index)?.get(keyForm)?.setErrors({ duplicate: null });
		form.at(index)?.get(keyForm)?.updateValueAndValidity({ emitEvent: false });
	}
	duplicatesUser = [];

	const dict = {};
	form.getRawValue().forEach((item: any, index: number) => {
		const name = item[keyForm]?.trim().toLowerCase();
		if (name !== null && name !== '') {
			dict[name] = dict[name] || [];
			dict[name].push(index);
		}
	});
	for (var key in dict) {
		if (dict[key].length > 1) {
			duplicatesUser = duplicatesUser.concat(dict[key]);
		}
	}
	for (const index of duplicatesUser) {
		form.at(index).get(keyForm)?.setErrors({ duplicate: true });
	}

	return {
		form,
		duplicatesUser,
	};
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
	return (formGroup: UntypedFormGroup) => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];
		if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
			return;
		}
		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ confirmedValidator: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}
