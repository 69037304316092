import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Publisher } from './publisher';

@Injectable({
	providedIn: 'root',
})
export class PublisherService {
	readonly publisher = new Publisher();

	public publish(key: string, data: any): void {
		this.publisher[key].next(data);
	}

	public follow(key: string): Observable<any> {
		return this.publisher[key].asObservable();
	}
}
