import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { HTTP_ERROR, URL_CONSTANT } from '@app/shared/constants';
import { ToastService } from '@app/shared/services';
import { InventoryInstanceService } from '@app/views/content-view/modules/inventory/services';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { RbacService } from '../../shared/services/rbac/rbac.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionGroupGuard {
	constructor(
		private readonly rbacService: RbacService,
		public inventoryInstanceService: InventoryInstanceService,
		private readonly router: Router,
		private readonly toastService: ToastService
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		return new Observable<boolean | UrlTree>((observer) => {
			const permissionKey = route?.data?.permissionKey || route?.parent?.data?.permissionKey;
			const params = route?.params;
			this.rbacService.currentPermissionsStage.pipe(take(1)).subscribe((permissions: any) => {
				if (params?.groupId) {
					observer.next(this.rbacService.handlePermissionForGroup(permissionKey, params.groupId, permissions));
					return;
				}
				if (params?.instanceId) {
					this.inventoryInstanceService.getIntanceDetail(params.instanceId).subscribe(
						(instanceInfo) => {
							observer.next(this.rbacService.handlePermissionForGroup(permissionKey, instanceInfo.groupId, permissions));
						},
						({ status }) => {
							if (status === HTTP_ERROR.NOT_FOUND) {
								observer.next(this.router.parseUrl(URL_CONSTANT.NOT_FOUND));
							} else {
								if (status) {
									this.toastService.add({
										severity: 'negative',
										summary: 'Failed to retrieve instance detail information',
									});
								}

								observer.next(false);
							}
						}
					);
				} else {
					observer.next(this.router.parseUrl(URL_CONSTANT.PERMISSION_DENIED));
				}
			});
		});
	}
}
