import { CONSTANT } from '@app/shared/constants';

/* eslint-disable */
export const INVENTORY_SCAN_HISTORY = {
	GET_SCAN_HISTORY: {
		url: `${CONSTANT.URL_PREFIX}report/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	DOWNLOAD_REPORT: {
		url: `${CONSTANT.URL_PREFIX}report/download`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	DOWNLOAD_REPORT_JSON: {
		url: `${CONSTANT.URL_PREFIX}report/download/json`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	DELETE_REPORT: {
		url: `${CONSTANT.URL_PREFIX}report`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: true,
		headers: {},
	},
	EDIT_REPORT_NAME: {
		url: `${CONSTANT.URL_PREFIX}report/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	RENAME_REPORTS: {
		url: `${CONSTANT.URL_PREFIX}report/rename`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
};
/* eslint-enable */
