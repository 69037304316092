import { Injectable } from '@angular/core';
import { IGeneralTableCriteria, IUserInfo } from '@app/shared/models';
import { IAccount } from '@app/views/content-view/modules/settings/model';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { RequestService } from '../request.service';
import { ACCOUNT, DX_PROFILE, ORG_LIST } from './account.api';

@Injectable({
	providedIn: 'root'
})
export class AccountApiService {

	constructor(private readonly requestService: RequestService, private readonly apollo: Apollo) { }

	public getInventoryInfo(): Observable<any> {
		return this.requestService.action(ACCOUNT.getInventoryInfo);
	}

	public userInfo(): Observable<IUserInfo> {
		return this.requestService.action(ACCOUNT.user_info);
	}

	public getAccountList(params: IGeneralTableCriteria): Observable<IAccount> {
		return this.requestService.action(ACCOUNT.getAccountList, params);
	}

	public getDxAccountList(): Observable<any> {
		return this.apollo.query({ query: ORG_LIST, errorPolicy: 'all' });
	}

	public getDxProfile(): Observable<any> {
		return this.apollo.query({ query: DX_PROFILE, errorPolicy: 'all' });
	}
}
