import { EventEmitter, Injectable } from '@angular/core';
import { CONSTANT } from '@app/shared/constants';
import { environment } from '@env/environment';
import { AES, enc, mode, pad } from 'crypto-js';
/**
 * How many minutes before JWT token expiration
 * do we attempt to refresh the token?
 */
const SHOW_SESSION_EXTENSION_POPUP_BEFORE_EXP_MIN = 9.9;

/**
 * How many minutes of inactivity makes the
 * user being considered idle?
 */
const ACTIVITY_ASSESSMENT_PERIOD_MIN = 15;

@Injectable({
	providedIn: 'root',
})
export class CmJwtService {
	constructor() {
		// do nothing
	}

	sessionExpired = new EventEmitter<any>();
	refreshToken = new EventEmitter<any>();
	showSessionExtensionPopup = new EventEmitter<any>();
	successTokenInit = new EventEmitter<any>();

	sessionExtensionPopupTimeout: any;
	sessionExpirationTimeout: any;

	public saveToken(token: any, source: any) {
		const encryptedData = AES.encrypt(token, enc.Base64.parse(environment.jwtEncBase64Key), {
			mode: mode.ECB,
			padding: pad.Pkcs7,
		}).toString();

		localStorage.setItem(CONSTANT.AUTH.JWT_TOKEN, encryptedData);
		this.init('save-token');
	}

	public getDecryptedToken(token: any) {
		if (!(typeof token === 'string' && token.length)) {
			return null;
		}

		try {
			return AES.decrypt(token.toString(), enc.Base64.parse(environment.jwtEncBase64Key), {
				mode: mode.ECB,
				padding: pad.Pkcs7,
			}).toString(enc.Utf8);
		} catch {
			return null;
		}
	}

	public isTokenInLocalStorage() {
		const token = localStorage.getItem(CONSTANT.AUTH.JWT_TOKEN);
		return typeof token === 'string' && !!token.length;
	}

	public isMyOpswatTokenInLocalStorage() {
		const token = localStorage.getItem(CONSTANT.AUTH.DX_TOKEN);
		return typeof token === 'string' && !!token.length;
	}

	public getEncryptedJwtToken() {
		const token: any = localStorage.getItem(CONSTANT.AUTH.JWT_TOKEN);
		return decodeURIComponent(token);
	}

	public getJwtToken() {
		const token = this.getEncryptedJwtToken();
		return this.getDecryptedToken(token);
	}

	public getDXToken() {
		return localStorage.getItem(CONSTANT.AUTH.DX_TOKEN);
	}
	public getJwtDecodedToken() {
		const token = this.getJwtToken();
		return this.decodeJwt(token);
	}

	public getJwtExpiration() {
		const token: any = this.getJwtDecodedToken();
		return (token && token.exp) || null;
	}

	/**
	 * init function gets called in two cases:
	 * - retrieving and saving new token to LS (saveToken function below)
	 * - app initialization (initSessionExpiration function in app.module.ts)
	 */
	public init(source: any) {
		/**
		 * Case -1:
		 * my opswat token not found, nothing to do at this
		 * point, emit sessionExpired event
		 **/
		if (!this.isMyOpswatTokenInLocalStorage() && environment.myOpswatAuthen) {
			this.sessionExpired.emit('my-opswat-token-not-found');
			return;
		}
		/**
		 * Case 0:
		 * token not found, nothing to do at this
		 * point, emit sessionExpired event
		 */
		if (!this.isTokenInLocalStorage()) {
			this.sessionExpired.emit('token-not-found');
			return;
		}
		const timestamp = this.getJwtExpiration();
		/**
		 * Case 1:
		 * timestamp is not a number, nothing to do
		 * at this point, emit sessionExpired event
		 */
		if (typeof timestamp !== 'number') {
			this.sessionExpired.emit('jwt-timestamp-not-found');
			return;
		}
		const jwtExpiresInMinutes = this.getMillisUntilTimestamp(timestamp) / 60000;
		/**
		 * Case 2:
		 * JWT expires in more than 10 minutes
		 */
		if (jwtExpiresInMinutes >= SHOW_SESSION_EXTENSION_POPUP_BEFORE_EXP_MIN) {
			this.scheduleSessionExtensionAttempt();
			this.scheduleSessionExpiration();
			this.successTokenInit.emit(true);
			return;
		}
		/**
		 * Case 3:
		 * JWT expires in less than 10 minutes
		 */
		if (jwtExpiresInMinutes < SHOW_SESSION_EXTENSION_POPUP_BEFORE_EXP_MIN && jwtExpiresInMinutes > 0) {
			this.attemptSessionExtension();
			this.scheduleSessionExpiration();
			this.successTokenInit.emit(true);
			return;
		}
		/**
		 * Case 4:
		 * JWT already expired
		 */
		if (jwtExpiresInMinutes <= 0) {
			this.sessionExpired.emit('jwt-already-expired');
		}
	}

	/**
	 * JWT already expired
	 */
	public isExpireToken() {
		const timestamp = this.getJwtExpiration();
		const jwtExpiresInMinutes = this.getMillisUntilTimestamp(timestamp) / 60000;

		if (jwtExpiresInMinutes <= 0) {
			this.sessionExpired.emit('jwt-already-expired');
			return false;
		}

		return true;
	}

	/**
	 * Emit session expiration event based
	 * on JWT expiration time
	 */
	private scheduleSessionExpiration() {
		const timestamp = this.getJwtExpiration();
		const jwtExpiresInMillis = this.getMillisUntilTimestamp(timestamp);
		if (this.sessionExpirationTimeout) {
			clearTimeout(this.sessionExpirationTimeout);
		}
		this.sessionExpirationTimeout = setTimeout(() => {
			this.sessionExpired.emit('scheduled-logout');
		}, jwtExpiresInMillis);
	}

	/**
	 * Attempt session extension based
	 * on JWT expiration time
	 */
	private scheduleSessionExtensionAttempt() {
		const timestamp = this.getJwtExpiration();
		const jwtExpiresInMillis = this.getMillisUntilTimestamp(timestamp);
		const attemptSessionExtensionInMillis = jwtExpiresInMillis - SHOW_SESSION_EXTENSION_POPUP_BEFORE_EXP_MIN * 60000;
		if (this.sessionExtensionPopupTimeout) {
			clearTimeout(this.sessionExtensionPopupTimeout);
		}

		console.log(`${new Date()} refresh trigger in ${new Date(timestamp * 1000 - SHOW_SESSION_EXTENSION_POPUP_BEFORE_EXP_MIN * 60000)}`);
		this.sessionExtensionPopupTimeout = setTimeout(() => {
			this.attemptSessionExtension();
		}, attemptSessionExtensionInMillis);
	}

	/**
	 * Attempt session extension based on
	 * user activity.
	 * If user is active => refresh token, else offer session
	 * extension popup
	 */
	private attemptSessionExtension() {
		if (this.isUserActive()) {
			this.refreshToken.emit();
			return;
		}

		this.showSessionExtensionPopup.emit();
	}

	public getMillisUntilTimestamp(timestamp: any): number {
		if (typeof timestamp !== 'number') {
			return 0;
		}
		timestamp *= 1000;
		return timestamp - Date.now();
	}

	public isUserActive() {
		const now = Date.now();
		const lastActivity = localStorage.getItem('la');
		return (
			typeof lastActivity === 'string' &&
			!Number.isNaN(Number(lastActivity)) &&
			now - parseInt(lastActivity, 10) < ACTIVITY_ASSESSMENT_PERIOD_MIN * 60000
		);
	}

	public decodeJwt(token: any) {
		if (!(typeof token === 'string' && token.length)) {
			return null;
		}
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const mapFce = (char: any) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(mapFce).join(''));
		return JSON.parse(jsonPayload);
	}
}
