export enum DIRECTORY_TYPE {
	COGNITO = 'COGNITO',
	LOCAL_USER = 'LOCAL',
	AD = 'AD',
	CUSTOM_SSO = 'CUSTOM_SSO',
}

export enum DIRECTORY_ACTION {
	UPDATE_STATUS = 'update-status',
	DELETE = 'delete',
}

export enum USER_DIRECTORY_TYPE {
	LOCAL = 'LOCAL',
	AD = 'AD',
	SAML = 'SAML',
}

export enum DIRECTORY_EDITOR_ACTION {
	ADD_NEW = 'add',
	DETAIL = 'edit',
}

export const DIRECTORY_TYPE_TITLE = {
	LOCAL: 'Local',
	AD: 'Active Directory',
	SAML: 'Single Sign-On (SSO)',
};
