<div class="dialog-small__header">
	<h4>Your Session Will Expire</h4>
	<mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-small__content">
	<p *ngIf="!failedToRefresh" class="m-t-md">Your session will expire soon. Do you want to extend the session?</p>
	<ng-container *ngIf="failedToRefresh">
		<p class="warn m-t-md m-b-sm">Session failed to be extended, redirecting you to a log in page in</p>
		<h3 class="warn w-100-p m-t-md m-b-sm align-center">{{ timeLeft }} seconds</h3>
	</ng-container>
</div>
<div class="dialog-small__footer">
	<mat-divider></mat-divider>
	<div class="flex flex-space-between m-t-md">
		<a mat-button color="neutral" class="outline none-pl" (click)="logOut()"> End Session </a>
		<button mat-button color="primary" (click)="onExtendSession()" [disabled]="failedToRefresh">Extend Session</button>
	</div>
</div>
