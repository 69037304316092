import { DASHBOARD_COLORS } from '../metadefender-kiosk/overview/dashboard/constants';
import { abbreviateNumber, gridChart } from '../metadefender-kiosk/overview/dashboard/utils';
import { AxisChartOptions, NonAxisChartOptions } from '../models';
import { DEFAULT_FONT_STYLE } from './inventory.constants';
import { crosshairs } from './media-firewall-chart-option.constants';

export const DEVICE_LICENSE_STATUS_CHART_OPTION: Partial<NonAxisChartOptions> = {
	series: [],
	colors: ['#D00300', '#FD760D', '#FDB00D', '#4C5560', '#B5C1DB'],
	labels: [],
	chart: {
		width: 450,
		type: 'donut',
		toolbar: {
			show: false,
		},
	},
	legend: {
		show: true,
		position: 'right',
		fontFamily: DEFAULT_FONT_STYLE,
		fontSize: '13',
		fontWeight: '500',
		horizontalAlign: 'center',
		floating: false,
		markers: {
			radius: 0,
			width: 2,
			height: 20,
		},
		itemMargin: {
			horizontal: 5,
			vertical: 0,
		},
		formatter: function (seriesName, opts) {
			return `<div class="flex flex-space-between">
						<div>${abbreviateNumber(opts.w.config.series[opts.seriesIndex])}</div>
						<div>${seriesName}</div>
					</div>`;
		},
	},
	plotOptions: {
		pie: {
			donut: {
				size: '60%',
				labels: {
					show: false,
					total: {
						show: false,
						label: '',
					},
					value: {
						show: true,
						fontFamily: DEFAULT_FONT_STYLE,
						offsetY: 0,
						fontSize: '15px',
						fontWeight: 500,
					},
					name: {
						show: false,
						fontFamily: DEFAULT_FONT_STYLE,
					},
				},
			},
		},
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		width: 1,
		colors: ['#fff'],
	},
	grid: {},
	states: {
		active: {
			filter: {
				type: 'none',
				value: 0,
			},
		},
	},
	responsive: [],
	fill: {},
};

export const AVERAGE_FILES_AND_TIME_CHART_OPTION: Partial<AxisChartOptions> = {
	series: [],
	chart: {
		height: 310,
		type: 'line',
		toolbar: {
			show: false,
		},
		id: 'average',
		group: 'combo-chart',
		zoom: {
			enabled: false,
		},
	},
	stroke: {
		width: [3, 3],
		curve: 'straight',
		dashArray: [0, 0],
		colors: [DASHBOARD_COLORS.LIGHT_GRAY, DASHBOARD_COLORS.DARK_BLUE],
	},
	fill: {
		colors: [DASHBOARD_COLORS.LIGHT_GRAY, DASHBOARD_COLORS.DARK_BLUE],
	},
	legend: {
		show: false,
	},
	xaxis: {
		crosshairs,
		labels: {
			maxHeight: 60,
			minHeight: 60,
		},
	},
	yaxis: {
		show: true,
		tickAmount: 4,
		title: {
			text: '',
		},
		labels: {
			formatter: function (value) {
				return abbreviateNumber(value);
			},
		},
		min: 0,
	},
	grid: gridChart,

	tooltip: {
		shared: true,
		intersect: false,
		x: {
			show: false,
		},
	},
};
