<div class="dialog-small__header">
	<h4>Warning</h4>
	<mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-small__content">
	<div class="m-b-lg m-t-md">
		You were removed from the account <span class="text-medium">{{ data.accountName }}</span
		>. <span class="note info cursor-pointer" (click)="terminate()">Sign in</span> again to access other accounts if any.
	</div>
</div>
<div class="dialog-small__footer">
	<mat-divider></mat-divider>
	<div class="flex flex-space-between m-t-md"></div>
</div>
