import { CONSTANT } from '@app/shared/constants';
import {
	DashboardMediaFirewallFileProcess,
	DashboardMediaFirewallMediaType,
	DashboardMediaFirewallScanSession,
} from './media-firewall-dashboard.mock';

/* eslint-disable */
export const MEDIA_FIREWALL_OVERVIEW = {
	GET_MEDIA_CHART: {
		url: `${CONSTANT.URL_PREFIX}dashboard/cricket/media`,
		mock: DashboardMediaFirewallMediaType,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_DATE_CHART: {
		url: `${CONSTANT.URL_PREFIX}dashboard/cricket/date-chart`,
		mock: DashboardMediaFirewallScanSession,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_PROCESSED_FILES: {
		url: `${CONSTANT.URL_PREFIX}dashboard/cricket/processed-files`,
		mock: DashboardMediaFirewallFileProcess,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_KIOSK_WITH_GROUP_ID: {
		url: `${CONSTANT.URL_PREFIX}instance/names`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_GROUP_WITH_PRODUCT_TYPE: {
		url: `${CONSTANT.URL_PREFIX}instance/groups`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
};
