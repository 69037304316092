import dayjs from 'dayjs';
import { DASHBOARD_COLORS, FILTER_ENUM } from '../constants';
import * as moment_ from 'moment';
import { dateHelper } from '@app/shared/utils';
const moment = moment_;

export const crosshairs = {
	show: true,
	width: 5,
	position: 'front',
	opacity: 1,
	stroke: {
		color: 'white',
		width: 2,
		dashArray: 0,
	},
	fill: {
		type: 'solid',
		color: DASHBOARD_COLORS.BLUE,
	},
	dropShadow: {
		enabled: false,
		top: 0,
		left: 0,
		blur: 1,
		opacity: 0.4,
	},
};

export const xAxisFormatter = function (currentFilter: any, processingOverviewFigureLabels: any, value: any) {
	if (currentFilter === FILTER_ENUM.ONEDAY) {
		const currentIndex = processingOverviewFigureLabels.indexOf(parseInt(value));
		const previousIndex = currentIndex - 1;
		const currentDate = dayjs(processingOverviewFigureLabels[currentIndex]).tz(dateHelper.getTimezone());
		const previousDate = dayjs(processingOverviewFigureLabels[previousIndex]).tz(dateHelper.getTimezone());

		if (previousDate && !currentDate.isSame(previousDate, 'day')) {
			return [
				currentDate.tz(dateHelper.getTimezone()).format('h A'),
				currentDate.tz(dateHelper.getTimezone()).format('DD MMM'),
				currentDate.tz(dateHelper.getTimezone()).format('YYYY'),
			];
		}
		return [currentDate.tz(dateHelper.getTimezone()).format('h A')];
	} else if (currentFilter === FILTER_ENUM.ONEMONTH || currentFilter === FILTER_ENUM.SEVENDAYS) {
		return [dayjs(value).tz(dateHelper.getTimezone()).format('DD MMM'), dayjs(value).tz(dateHelper.getTimezone()).format('YYYY')];
	} else {
		return [dayjs(value).tz(dateHelper.getTimezone()).format('MMM'), dayjs(value).tz(dateHelper.getTimezone()).format('YYYY')];
	}
};
