import { CONSTANT } from '@app/shared/constants';
import { IS_UPGRADE_OVA } from './upgrade-ova.mock';

export const UPGRADE_OVA = {
	checkUpgradeOVA: {
		url: `${CONSTANT.URL_PREFIX}op/upgrade/is-upgrading`,
		mock: IS_UPGRADE_OVA,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	CHECK_IMPORT_FILE: {
		url: `${CONSTANT.URL_PREFIX}onp/task/is-running`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
};
