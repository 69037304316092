import { NOTIFICATION_MESSAGE } from '../constants';
import { ToastService } from '../services/toast.service';

function messageForError500(msg: string) {
	const message = NOTIFICATION_MESSAGE.ERROR_500;

	return message.replace(/<do something>/, msg);
}

function messageForSystemDownError() {
	return NOTIFICATION_MESSAGE.SYSTEM_DOWN_ERROR;
}

export function showHttpErrorMsg(service: ToastService, httpCode: number, customMessage: string, errorMessage: string) {
	if (httpCode === 502 || httpCode === 503) {
		service.add({ severity: 'negative', summary: messageForSystemDownError() });
	} else if (httpCode === 500) {
		service.add({ severity: 'negative', summary: messageForError500(customMessage) });
	} else {
		if (errorMessage) {
			service.add({ severity: 'negative', summary: errorMessage });
		}
	}
}
