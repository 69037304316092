
export const DashboardMediaFirewallMediaType: any = [
	{
		id: 'USB-C',
		total: 25,
		success: 0,
		failed: 25,
	},
	{
		id: 'USB-A',
		total: 3,
		success: 2,
		failed: 1,
	},
	{
		id: 'SD CARD',
		total: 19,
		success: 0,
		failed: 19,
	},
];

export const DashboardMediaFirewallFileProcess: any = {
	verdict: [
		{ id: 'Infected', count: 12 },
		{ id: 'Encrypted Archive2', count: 11 },
		{ id: 'Encrypted Archive3', count: 10 },
		{ id: 'Others', count: 6 },
		{ id: 'Allowed', count: 7 },
	],
	blocked: [
		{ id: 'Infected', count: 12 },
		{ id: 'Encrypted Archive2', count: 11 },
		{ id: 'Encrypted Archive3', count: 10 },
		{ id: 'Others', count: 6 },
		{ id: 'Allowed', count: 7 },
	],
};

export const DashboardMediaFirewallScanSession: any = [
	{
		startTime: 1696823551000,
		file: {
			total: 60,
			blocked: 30,
			allowed: 20,
			concealed: 10,
		},
		session: {
			total: 14,
			other: 0,
			totalSuccess: 1,
			totalFailed: 13,
			badSignature: 6,
			earlyMediaEjection: 3,
			noManifest: 2,
			canceled: 2,
		},
	},
	{
		startTime: 1696909951000,
		file: {
			total: 36,
			blocked: 0,
			allowed: 35,
			concealed: 1,
		},
		session: {
			total: 27,
			other: 5,
			totalSuccess: 3,
			totalFailed: 24,
			badSignature: 5,
			errorMedia: 13,
			noManifest : 0,
			canceled: 1,
		},
	},
	{
		startTime: 1696978351000,
		file: {
			total: 70,
			blocked: 25,
			allowed: 40,
			concealed: 5,
		},
		session: {
			total: 47,
			other: 0,
			totalSuccess: 5,
			totalFailed: 42,
			badSignature: 0,
			errorMedia: 30,
			noManifest: 10,
			canceled: 2,
		},
	},
	{
		startTime: 1697842351000,
		file: {
			total: 116,
			blocked: 50,
			allowed: 60,
			concealed: 6,
		},
		session: {
			total: 15,
			totalSuccess: 2,
			totalFailed: 13,
			badSignature: 6,
			errorMedia: 3,
			noManifest: 2,
			canceled: 2,
			other: 0,
		},
	},
	{
		startTime: 1698274351000,
		file: {
			total: 133,
			blocked: 49,
			allowed: 80,
			concealed: 4,
		},
		session: {
			total: 27,
			totalSuccess: 6,
			totalFailed: 21,
			badSignature: 5,
			errorMedia: 3,
			noManifest: 2,
			canceled: 2,
			other: 9,
		},
	},
	{
		startTime: 1698965551000,
		file: {
			total: 95,
			blocked: 30,
			allowed: 45,
			concealed: 20,
		},
		session: {
			total: 32,
			totalSuccess: 10,
			totalFailed: 22,
			badSignature: 6,
			errorMedia: 10,
			noManifest: 2,
			canceled: 2,
			other: 2,
		},
	},
	{
		startTime: 1699656751000,
		file: {
			total: 564,
			blocked: 444,
			allowed: 90,
			concealed: 30,
		},
		session: {
			total: 24,
			totalSuccess: 12,
			totalFailed: 12,
			badSignature: 5,
			errorMedia: 3,
			noManifest: 1,
			canceled: 2,
			other: 1,
		},
	},
];

export const DashboardMediaFirewallSearch: any = {
	endTime: 1697167901000,
	instanceIds: [],
	groupsByProductType: [
		{
			productType: 'UF3',
			groupIds: [],
		},
	],
	utcOffset: '+07:00',
	amount: 12,
	unit: 'month',
};
