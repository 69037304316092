export const PROCESSING_HISTORY_RESULT = {
	in_progress: { name: 'In Progress', id: 'In Progress' },
	no_threat_detected: { name: 'No Threat Detected', id: 'No Threat Detected' },
	whitelisted: { name: 'Allowlisted', id: 'Allowlisted' },
	blacklisted: { name: 'Blocklisted', id: 'Blocklisted' },
	infected: { name: 'Infected', id: 'Infected' },
	suspicious: { name: 'Suspicious', id: 'Suspicious' },
	failed: { name: 'Failed', id: 'Failed' },
	not_scanned: { name: 'Not Scanned', id: 'Not Scanned' },
	exceeded_archive_depth: { name: 'Exceeded Archive Depth', id: 'Exceeded Archive Depth' },
	exceeded_archive_size: { name: 'Exceeded Archive Size', id: 'Exceeded Archive Size' },
	exceeded_archive_file_number: { name: 'Exceeded Archive File Number', id: 'Exceeded Archive File Number' },
	encrypted_archive: { name: 'Encrypted Archive', id: 'Encrypted Archive' },
	exceeded_archive_timeout: { name: 'Exceeded Archive Timeout', id: 'Exceeded Archive Timeout' },
	mismatch: { name: 'Mismatch', id: 'Mismatch' },
	potentially_vulnerable_file: { name: 'Potentially Vulnerable File', id: 'Potentially Vulnerable File' },
	sensitive_data_found: { name: 'Sensitive Data Found', id: 'Sensitive Data Found' },
	yara_rule_matched: { name: 'Yara Rule Matched', id: 'Yara Rule Matched' },
	cancelled: { name: 'Cancelled', id: 'Cancelled' },
	password_protected_document: { name: 'Password Protected Document', id: 'Password Protected Document' },
	potentially_unwanted: { name: 'Potentially Unwanted', id: 'Potentially Unwanted' },
	has_vulnerability: { name: 'Has Vulnerability', id: 'Has Vulnerability' },
	sanitized: { name: 'Sanitized', id: 'Sanitized' },
	unsupported_file_type: { name: 'Unsupported File Type', id: 'Unsupported File Type' },
	sanitization_failed: { name: 'Sanitization Failed', id: 'Sanitization Failed' },
	sanitization_timedout: { name: 'Sanitization Timed Out', id: 'Sanitization Timed Out' },
	blocked_verdict_by_deep_cdr: { name: 'Blocked Verdict by Deep CDR', id: 'Blocked Verdict by Deep CDR' },
	encryption_failed: { name: 'Encryption Failed', id: 'Encryption Failed' },
	encryption_timedout: { name: 'Encryption Timed Out', id: 'Encryption Timed Out' },
	extraction_failed: { name: 'Extraction Failed', id: 'Extraction Failed' },
	prevent_data_leak_failed: { name: 'Prevent Data Leak Failed', id: 'Prevent Data Leak Failed' },
	prevent_data_leak_timed_out: { name: 'Prevent Data Leak Timed Out', id: 'Prevent Data Leak Timed Out' },
	suspicious_verdict_by_sandbox: { name: 'Suspicious Verdict by Sandbox', id: 'Suspicious Verdict by Sandbox' },
	likely_malicious_verdict_by_sandbox: { name: 'Likely Malicious Verdict by Sandbox', id: 'Likely Malicious Verdict by Sandbox' },
	malicious_verdict_by_sandbox: { name: 'Malicious Verdict by Sandbox', id: 'Malicious Verdict by Sandbox' },
	blocked_verdict_by_sandbox: { name: 'Blocked Verdict by Sandbox', id: 'Blocked Verdict by Sandbox' },
	sandbox_failed: { name: 'Sandbox Failed', id: 'Sandbox Failed' },
	sandbox_timeout: { name: 'Sandbox Timed Out', id: 'Sandbox Timed Out' },
	global_timeout_exceeded: { name: 'Global Timeout Exceeded', id: 'Global Timeout Exceeded' },
	vulnerable_verdict_by_sbom: { name: 'Vulnerable Verdict by SBOM', id: 'Vulnerable Verdict by SBOM' },
	weak_vulnerable_verdict_by_sbom: { name: 'Weak-vulnerable Verdict by SBOM', id: 'Weak-vulnerable Verdict by SBOM' },
	blocked_verdict_by_sbom: { name: 'Blocked Verdict by SBOM', id: 'Blocked Verdict by SBOM' },
	non_vulnerable_verdict_by_sbom: { name: 'Non-vulnerable Verdict by SBOM', id: 'Non-vulnerable Verdict by SBOM' },
	sbom_failed: { name: 'SBOM Failed', id: 'SBOM Failed' },
	sbom_timed_out: { name: 'SBOM Timed Out', id: 'SBOM Timed Out' },
	blocked_by_post_action: { name: 'Blocked by Post Action', id: 'Blocked by Post Action' },
	known_bad: { name: 'Known Bad', id: 'Known Bad' },
	known_good: { name: 'Known Good', id: 'Known Good' },
	reputation_failed: { name: 'Reputation Failed', id: 'Reputation Failed' },
	reputation_timed_out: { name: 'Reputation Timed Out', id: 'Reputation Timed Out' },
	allowed_verdict_by_coo: { name: 'Allowed Verdict by COO', id: 'Allowed Verdict by COO' },
	blocked_verdict_by_coo: { name: 'Blocked Verdict by COO', id: 'Blocked Verdict by COO' },
	unknown_verdict_by_coo: { name: 'Unknown Verdict by COO', id: 'Unknown Verdict by COO' },
	coo_failed: { name: 'COO Failed', id: 'COO Failed' },
	coo_timed_out: { name: 'COO Timed Out', id: 'COO Timed Out' },
};

export const PROCESSING_HISTORY_ACTION = {
	sanitized: { name: 'Sanitized', id: 'Sanitized' },
	sanitized_partially: { name: 'Sanitized Partially', id: 'Sanitized Partially' },
	sanitized_failed: { name: 'Sanitization Failed', id: 'Sanitization Failed' },
	sanitized_skipped: { name: 'Sanitization Skipped', id: 'Sanitization Skipped' },
	sanitized_timeout: { name: 'Sanitization Timed Out', id: 'Sanitization Timed Out' },
};

export const PROCESSING_HISTORY_WORKFLOW = {
	kiosk: { name: 'Kiosk', id: 'Kiosk' },
	file_process: { name: 'File process', id: 'File process' },
	file_process_wo_archive: { name: 'File process without archive', id: 'File process without archive' },
	metadefender_vault: { name: 'MetaDefender Vault', id: 'MetaDefender Vault' },
	email_security: { name: 'Metadefender Email Gateway Security', id: 'Metadefender Email Gateway Security' },
	email_security_wo_cdr: {
		name: 'Metadefender Email Gateway Security without Deep CDR',
		id: 'Metadefender Email Gateway Security without Deep CDR',
	},
	secure_storage: { name: 'MetaDefender for Secure Storage', id: 'MetaDefender for Secure Storage' },
};

export const PROCESSING_HISTORY_STATUS = {
	allowed: { name: 'Allowed', id: 'Allowed' },
	blocked: { name: 'Blocked', id: 'Blocked' },
	processing: { name: 'Processing', id: 'Processing' },
};

// This setting will be configurable next phase
export const PROCESSING_HISTORY_COLOR = {
	[PROCESSING_HISTORY_RESULT.infected.id]: '#FF1919',
	[PROCESSING_HISTORY_RESULT.sensitive_data_found.id]: '#ED6706',
	[PROCESSING_HISTORY_RESULT.potentially_vulnerable_file.id]: '#FDBD0D',
	[PROCESSING_HISTORY_RESULT.suspicious.id]: '#FDBD0D',
	others: '#1B273C',
};
