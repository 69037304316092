import { AbstractControl, ValidationErrors } from '@angular/forms';

export function noWhitespaceValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		// if value is empty, it does not have whitespace so return
		if (!control.value || !control.value?.length) {
			return null;
		}
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { whitespace: true };
	};
}
