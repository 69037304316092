import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { VALIDATION_CONSTANT } from '../constants';

export function validateSameEntityNameUser(entityName: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (entityName) {
			const value = control.value;
			if (value === entityName) {
				return { sameEntityName: true };
			}
		}
		return null;
	};
}

export function emailValidator(emailRegex: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (!control.value || control.value.trim() === '') {
			return null;
		}
		if (!emailRegex.test(control.value.trim()) && !VALIDATION_CONSTANT.EMAIL.REGEX.test(control.value)) {
			return { pattern: { value: control.value } };
		} else {
			return null;
		}
	};
}

export function numberValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value || !control.value?.length) {
			return null;
		}
		const isInvalid = isNaN(control.value);
		return isInvalid ? { number: { value: control.value } } : null;
	};
}

export function mailingListValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value) {
			const mailingList = control.value.split('\n');
			const mailingSet = new Set();
			for (const email of mailingList) {
				mailingSet.add(email.trim());
				if (email.trim() && !VALIDATION_CONSTANT.EMAIL.REGEX.test(email.trim())) {
					return { email: true };
				}
			}
			if (mailingList.length !== mailingSet.size) {
				return { duplicated: true };
			}
			return null;
		}
		return null;
	};
}
