// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	version: '8.1.8',
	production: false,
	isHttps: true,
	jwtEncBase64Key: 'VkhWaGJqRXlNelExTmpjNA==',
	vaultAPIServer: 'https://portalsft.opswat.com',
	ocmAPIServer: 'https://product.my-dev.opswat.com',
	ssoLoginUrl: 'https://product.my-dev.opswat.com/api/console/auth/login',
	productUrl: 'https://product.my-dev.opswat.com',
	ssoLogoutUrl: 'https://id-staging.opswat.com/logout?redirect=https://my-dev.opswat.com&app=appOCM0001',
	ssoChangePasswordUrl: 'https://my-staging.opswat.com/my-information/security',
	myOpswat: 'https://my-staging.opswat.com',
	myOpswatEndpoint: 'https://apiv1.my-staging.opswat.com/graphql/',
	myOpswatAuthen: false,
	onCloud: true,
	engineStatusUrl: 'https://enginestatus.dl.opswat.com/avengines/',
	environment: 'dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
