import { CONSTANT } from '@app/shared/constants';

/* eslint-disable */
export const AUTH_API = {
	logout: {
		url: `${CONSTANT.URL_PREFIX}auth/logout`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	expired_session: {
		url: `${CONSTANT.URL_PREFIX}auth/expireSession`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	refresh_token: {
		url: `${CONSTANT.URL_PREFIX}token/refresh`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	local_login: {
		url: `${CONSTANT.URL_PREFIX}auth/processLogin`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	isSetupWizard: {
		url: `${CONSTANT.URL_PREFIX}op/setup/is-success`,
		mock: true,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	logout_onprem: {
		url: `${CONSTANT.URL_PREFIX}auth/logout`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
};
/* eslint-enable */
