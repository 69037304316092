import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards';
import { URL_CONSTANT } from './shared/constants';
import { AccountInformationResolver } from './core/resolvers/account-information.resolver';

const routes: Routes = [
	{
		path: 'nlv',
		loadChildren: () => import('./views/non-login-view/non-login-view.module').then((m) => m.NonLoginViewModule),
	},
	{
		path: 'non-bg',
		canActivate: [AuthGuard],
		loadChildren: () => import('./views/non-background-view/non-background-view.module').then((m) => m.NonBackgroundViewModule),
	},
	{
		path: '',
		canActivate: [AuthGuard],
		loadChildren: () => import('./views/content-view/content-view.module').then((m) => m.ContentViewModule),
		resolve: {
			accountInformation: AccountInformationResolver,
		},
		data: {
			showFooter: false,
		}
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(private readonly router: Router) {
		this.router.errorHandler = (error) => {
			this.router.navigate([URL_CONSTANT.NOT_FOUND]);
		};
	}
}
