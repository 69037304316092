export enum PERMISSION {
	NONE = 'NONE',
	VIEW_ONLY = 'VIEW_ONLY',
	FULL = 'FULL',
}

export const PERMISSION_KEY = {
	dashboard: 'dashboard',
	inventory: 'inventory',
	userManagement: 'userManagement',
	eventsHistory: 'eventsHistory',
	settings: 'settings',
	userProfile: 'userProfile',
	licenses: 'licenses',
};

export const DEFAULT_INVENTORY_PERMISSION = ['VIEW_ONLY', 'FULL'];

export const PERMISSION_TAB_KEY = {
	userManagement: {
		userGroup: 'userGroup',
		role: 'role',
		userDirectory: 'userDirectory',
	},
	settings: {
		account: 'account',
		license: 'license',
		products: 'products',
		modules: 'modules',
		certificates: 'certificates',
		servers: 'servers',
		reports: 'reports',
		tools: 'tools',
		importAndExport: 'importAndExport',
	},
	licenses: {
		myOpswat: 'myOpswat',
		products: 'products',
		mdcore: 'mdcore',
		kioskK: 'k-series',
		kioskL: 'l-series',
	},
};

export const KEY_OF_GROUP_ID_VALUE = {
	GROUP_ID: 'groupId',
};

export const PERMISSION_PREDEFINED = {
	FULL: {
		dashboard: {
			level: 'FULL',
			isCustom: false,
		},
		inventory: {
			level: 'FULL',
			isCustom: false,
			mk5: {
				level: 'FULL',
				isCustom: false,
				groups: [
					{
						groupId: '6274903a8c864b02e2e30a0e',
						level: 'NONE',
					},
					{
						groupId: '6274a4b48c864b02e2e30a11',
						level: 'VIEW_ONLY',
					},
				],
			},
			kiosk: {
				level: 'FULL',
				isCustom: false,
			},
			empty: true,
		},
		userManagement: {
			level: 'FULL',
			isCustom: false,
			userGroup: 'NONE',
			role: 'VIEW_ONLY',
			directory: 'FULL',
		},
		eventsHistory: {
			level: 'FULL',
			isCustom: false,
		},
		settings: {
			level: 'FULL',
			isCustom: false,
		},
		licenses: {
			level: 'FULL',
			isCustom: false,
		},
		userProfile: {
			level: 'FULL',
			isCustom: false,
		},
	},
	VIEW_ONLY: {
		dashboard: {
			level: 'VIEW_ONLY',
			isCustom: false,
		},
		inventory: {
			level: 'VIEW_ONLY',
			isCustom: false,
			empty: true,
		},
		userManagement: {
			level: 'VIEW_ONLY',
			isCustom: false,
		},
		eventsHistory: {
			level: 'VIEW_ONLY',
			isCustom: false,
		},
		settings: {
			level: 'VIEW_ONLY',
			isCustom: false,
		},
		licenses: {
			level: 'VIEW_ONLY',
			isCustom: false,
		},
		userProfile: {
			level: 'VIEW_ONLY',
			isCustom: false,
		},
	},
};
