import { Injectable } from '@angular/core';
import { IUserInfo } from '@app/shared/models';
import { Observable } from 'rxjs';
import { RequestService } from '../request.service';
import { ACCESS_CONTROL } from './access-control.api';

@Injectable({
	providedIn: 'root'
})
export class AccessControlApiService {
	constructor(private readonly requestService: RequestService) { }

	public getInventoryInfo(): Observable<any> {
		return this.requestService.action(ACCESS_CONTROL.getInventoryInfo);
	}

	public userInfo(): Observable<IUserInfo> {
		return this.requestService.action(ACCESS_CONTROL.user_info);
	}
}
