import dayjs from 'dayjs';
import { dateHelper } from '@app/shared/utils';
const ABB_LENGTH = 5; // Length condition to start abbreviate

export const mapAllProducts = [
	{
		key: 'filetypeTime',
		value: 'Filetype',
	},
	{
		key: 'extractionTime',
		value: 'Archive extraction',
	},
	{
		key: 'cdrTime',
		value: 'Deep CDR',
	},
	{
		key: 'dlpTime',
		value: 'Proactive DLP',
	},
	{
		key: 'vulTime',
		value: 'Vulnerability assessment',
	},
	{
		key: 'sbomTime',
		value: 'SBOM',
	},
	{
		key: 'opswatfilescanTime',
		value: 'Adaptive Sandbox',
	},
	{
		key: 'yaraTime',
		value: 'Yara',
	},
	{
		key: 'cloudLookupTime',
		value: 'Cloud lookup',
	},
	{
		key: 'decryptedTime',
		value: 'Decryption',
	},
	{
		key: 'externalScanTime',
		value: 'External scanner',
	},
	{
		key: 'avScanTime',
		value: 'Metascan™',
	},
	{
		key: 'compressionTime',
		value: 'Archive compression',
	},
	{
		key: 'reputationTime',
		value: 'Reputation',
	},
	{
		key: 'waitChildFilesTime',
		value: 'Nested files',
	},
	{
		key: 'othersTime',
		value: 'Others',
	},
	{
		key: 'postActionTime',
		value: 'Post action',
	},
	{
		key: 'quarantineStorageTime',
		value: 'Quarantine storage',
	},
	{
		key: 'dlpStorageTime',
		value: 'Proactive DLP storage',
	},
	{
		key: 'cdrStorageTime',
		value: 'Deep CDR storage',
	},
	{
		key: 'cdrWaitTime',
		value: 'CDR waiting time',
	},
	{
		key: 'cooTime',
		value: 'Country of Origin',
	},
	{
		key: 'avWaitTime',
		value: 'Metascan™ waiting time',
	},
	{
		key: 'cdrCompressionTime',
		value: 'CDR Compression',
	},
	{
		key: 'dlpCompressionTime',
		value: 'DLP Compression',
	},
	{
		key: 'waitTime',
		value: 'Wait Time',
	},
];

export enum FILTER_ENUM {
	ONEDAY = '24H',
	SEVENDAYS = '7D',
	ONEMONTH = '30D',
	ONEYEAR = '12M',
	CUSTOM = 'CUSTOM',
}

export const abbreviateNumber = function (value: number) {
	if (value === undefined) {
		return '0';
	}

	if (value.toString().length < ABB_LENGTH) {
		return value.toString();
	}

	const suffixes = ['', 'K', 'M', 'B', 'T', 'Qd', 'Qn', 'Sx', 'Sp', 'Oc', 'N', 'Dc'];

	let indexSuffixes = 0;
	if (value === 0) {
		indexSuffixes = value;
	} else {
		indexSuffixes = Math.floor(Math.log(value) / Math.log(1000));
	}

	const numberBeforeSuffixes = parseFloat((value / Math.pow(1000, indexSuffixes)).toFixed(1));
	let result = numberBeforeSuffixes.toString();
	if (numberBeforeSuffixes === 1000) {
		result = '1';
		indexSuffixes++;
	}
	result += `${suffixes[indexSuffixes]}`;

	return result;
};

export const isAbbreviateNumber = function (value: number) {
	if (value === undefined) {
		return '0';
	}

	if (value.toString().length < ABB_LENGTH) {
		return true;
	}

	let indexSuffixes = 0;
	if (value === 0) {
		indexSuffixes = value;
	} else {
		indexSuffixes = Math.floor(Math.log(value) / Math.log(1000));
	}

	return indexSuffixes === 0;
};

export const numberWithCommas = (number: number) => {
	return number.toLocaleString();
};

export const startTimeBaseOnFilter = (currentFilter: any, startTime: any, amount: number) => {
	let startTimeString = '';
	if (currentFilter === FILTER_ENUM.ONEDAY) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('h A, DD MMM, YYYY');
	} else if (currentFilter === FILTER_ENUM.ONEMONTH || currentFilter === FILTER_ENUM.SEVENDAYS) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('DD MMM, YYYY');
	} else if (currentFilter === FILTER_ENUM.CUSTOM && amount <= 90) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('DD MMM, YYYY');
	} else {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('MMM, YYYY');
	}
	return startTimeString;
};

export const dataBaseOnFilter = (currentFilter: any) => {
	const dataZero: number[] = [];
	const filter = {
		[FILTER_ENUM.ONEDAY]: 24,
		[FILTER_ENUM.SEVENDAYS]: 7,
		[FILTER_ENUM.ONEMONTH]: 30,
		[FILTER_ENUM.ONEYEAR]: 12,
		[FILTER_ENUM.CUSTOM]: 'CUSTOM',
	};

	for (let index = 0; index < filter[currentFilter]; index++) {
		dataZero.push(0);
	}

	return dataZero;
};

export function findNextMultipleOfFour(num: number) {
	if (num < 20) {
		const remainder = num % 4;
		if (remainder === 0) {
			return num + 4;
		} else {
			return num + (4 - remainder);
		}
	}
	let multipleOfFour;
	const numberLength = num.toString().length;
	if (numberLength <= 3) {
		multipleOfFour = 20;
	} else if (numberLength <= 4) {
		multipleOfFour = 100;
	} else {
		multipleOfFour = 1000;
	}
	num = Math.floor((num + multipleOfFour - 1) / multipleOfFour) * multipleOfFour;

	return num;
}

export const minWidthLabel = (number: number) => {
	if (number === 0) {
		return 10;
	}
	let length = abbreviateNumber(number / 4).length;
	for (let i = number / 2; i <= number; i += number / 4) {
		if (length < abbreviateNumber(i).length) {
			length = abbreviateNumber(i).length;
		}
	}
	const width = {
		1: 10,
		2: 15,
		3: 20,
		4: 25,
		5: 30,
		6: 35,
		7: 40,
		8: 45,
		9: 50,
	};

	return width[length];
};
