import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-permission-denied-dialog',
	templateUrl: './permission-denied-dialog.component.html',
})
export class PermissionDeniedDialogComponent {
	constructor(public dialogRef: MatDialogRef<PermissionDeniedDialogComponent>) {}

	onCancel() {
		this.dialogRef.close(false);
	}
}
