export const ACCESS_LEVEL = {
	VIEW_ONLY: {
		KEY: 'VIEW_ONLY',
		VALUE: 'View Only',
	},
	FULL_ACCESS: {
		KEY: 'FULL',
		VALUE: 'Full Access',
	},
	NONE: {
		KEY: 'NONE',
		VALUE: 'No Access',
	},
	CUSTOM: {
		KEY: 'CUSTOM',
		VALUE: 'Custom',
	},
};

export enum ROLE_TYPE {
	CUSTOM = 'custom',
	ADMIN = 'admin',
	VIEW_ONLY = 'readonly',
}

export const ACCESS_LEVEL_LIST = [
	{
		key: ACCESS_LEVEL.FULL_ACCESS.KEY,
		value: ACCESS_LEVEL.FULL_ACCESS.VALUE,
	},
	{
		key: ACCESS_LEVEL.VIEW_ONLY.KEY,
		value: ACCESS_LEVEL.VIEW_ONLY.VALUE,
	},
	{
		key: ACCESS_LEVEL.NONE.KEY,
		value: ACCESS_LEVEL.NONE.VALUE,
	},
];

export function deleteErrorMessage(name: string) {
	return `The role ${name} was deleted by another administrator`;
}
