<div class="dialog-small__header">
	<h4>Permission denied</h4>
	<mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-small__content">
	<div class="m-b-lg m-t-md">
		<span> Please contact your administrators for help. </span>
	</div>
</div>
<div class="dialog-small__footer">
	<mat-divider></mat-divider>
	<div class="flex flex-end m-t-md">
		<button id="leaveBtn" cdkFocusInitial mat-button color="primary" class="m-l-sm" (click)="onCancel()">Cancel</button>
	</div>
</div>
